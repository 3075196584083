.heroComponent,
.articleHero,
.videoHeroClass {
    height: 100%;
    max-height: 400px;
    min-height: 400px;

    @media (min-width: 425px) {
        max-height: 565px;
        min-height: 565px;
    }

    @media screen and (min-width: 1025px) and (max-width: 1279px) {
        min-height: 450px;
        max-height: none;
    }
}

.heroComponent__contents {
    z-index: 2;
}

.mgnl-admin {
    .heroComponent__contents {
        transform: none;
    }
}

.heroComponent {
    z-index: 0;
}