.navSearch {
    .navSearch__icon {
        width: 25px;
        height: 25px;
        fill: var(--theme-text);
    }

    .navSearch__input {
        border-radius: 3px;
        padding: 5px 10px 5px 50px;
        width: 100%;
        height: 50px;
    }

    .navSearch__result {
        padding: 25px;
        border-radius: 0 0 5px 5px;
        width: 100%;

        h6 {
            font-size: 0.7rem;
            margin-bottom: 5px;
            opacity: 0.8;
            text-transform: uppercase;
        }

        .navSearch__resultList {
            font-size: 1rem;
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 10px;
            }
        }
    }
}

.navSearch--mobile {
    .navSearch__form {
        width: 100%;
    }

    .navSearch__icon {
        width: 20px;
        height: 20px;
        top: 15px;
        left: 17px;
        fill: var(--theme-text);
    }
}

.navSearch--overlay {
    &:after {
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;
        position: fixed;
    }
}

.nav__right--openSearch {
    margin-left: 15px;

    > .Area {
        justify-content: flex-end;
    }

    .navSearch {
        width: 700px;
    }

    .navSearch__icon {
        width: 20px;
        height: 20px;
        top: 15px;
        left: 20px;
        fill: var(--theme-text);
    }

    .navSearch__button {
        display: none;
    }

    .navSearch__form {
        display: block;
    }
}
