.authForm__wrap {
    min-height: 60vh;
}

.authForm--V2 {
    --theme-forms-border-color: #f6f6f6;
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;

    .authForm__container {
        background-color: var(--theme-forms-bg-color);
    }

    .authForm__header {
        background-color: var(--theme-forms-header-color);
    }

    .authForm__title {
        @apply text-2xl font-medium border-b-4 pb-3 mb-4;
        color: var(--theme-forms-title-color);
        border-color: var(--theme-forms-border-color);
    }

    h4 {
        @apply text-xl font-semibold;
        color: var(--theme-forms-subtitle-color);
    }

    h5 {
        @apply mt-4;
        color: var(--theme-forms-subtitle-color);
    }

    fieldset {
        @apply block;
    }

    legend {
        @apply border-b block w-full pb-2 mb-4 font-semibold;
        color: var(--theme-forms-subtitle-color);
        border-color: var(--theme-forms-border-color);
    }

    label {
        @apply block w-full pb-2 font-semibold;
        color: var(--theme-forms-subtitle-color);
    }

    iframe {
        @apply m-auto;
    }

    a:not(.swrx__button):not(.button) {
        color: var(--theme-link-text-color);

        &:hover {
            color: var(--theme-link-text-color-hover);
        }

        &.button {
            color: var(--theme-button-text-color);

            &:hover {
                border-color: var(--theme-button-text-color-hover);
                color: var(--theme-button-text-color-hover);
            }
        }
    }

    .capture_tip_validating {
        display: none;
    }

    p,
    form {
        .capture_checkbox label {
            display: inline;
        }
    }

    .password_wrap {
        input[type="text"] ~ .toggle_password .password_show {
            display: none;
        }

        input[type="password"] ~ .toggle_password .password_hide {
            display: none;
        }
    }

    select,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="date"] {
        @apply px-4 py-2 w-full rounded border  placeholder-gray-400 text-black;
        border-color: #dbdbdb;
        scroll-margin-top: 10em;
        color: var(--theme-forms-text-color);
    }

    input[type="checkbox"] {
        @apply mt-8 mr-4 transform scale-150;
        scroll-margin-top: 10em;

        &:focus-visible {
            outline: 2px solid $color-black;
        }
    }

    .invalid {
        border-color: #e50830 !important;
    }

    .capture_btn,
    input[type="submit"] {
        @apply float-right w-auto font-title overflow-hidden inline-block px-5 rounded-full border border-solid cursor-pointer py-3 lg:py-3 font-semibold;
    }

    .changePwBtn {
        @apply px-5 py-2 mt-4 lg:py-1 overflow-hidden inline-block rounded-full border border-solid text-center w-auto;
    }

    .authForm__errorMsg,
    .capture_form_error,
    .capture_tip_error {
        @apply text-red-600 my-2 px-0;
    }

    p.error-message {
        @apply text-red-600 px-0;
    }

    .authForm__successMsg {
        @apply text-green-600 my-2 px-0;
    }

    .capture_footer {
        @apply mt-2;
    }

    label {
        color: var(--theme-forms-label-color);

        a {
            @apply underline;
            color: var(--theme-link-text-color);

            &:hover {
                color: var(--theme-link-text-color-hover);
            }
        }

        p {
            @apply px-0 inline;
        }
    }

    .docCheck__inputWrap {
        min-height: 7rem;
    }

    // API Forms
    .authForm__item {
        select,
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="date"] {
            @apply my-0;
        }

        input[type="checkbox"] {
            @apply my-2 flex self-start;
            min-width: 13px;
        }

        .authForm__checkboxLabel {
            @apply font-normal;
            color: var(--theme-forms-text-color);
            word-break: break-word;
        }

        p.error-message {
            @apply pt-0;
        }

        .authForm__errWrap {
            @apply pb-3 pt-2;
        }
    }
}

.terms-modal {
    left: 50%;
    transform: translate(-50%, -50%);
}

input:read-only,
input:disabled,
select:disabled {
    background-color: #e2e2e2 !important;
    outline: none;
}

.form-text {
    @apply mb-4;
    padding-left: 28px;

    p {
        @apply px-0;
        @apply mt-1;
    }

    ul,
    ol {
        list-style: inherit;
        list-style-position: outside;
        padding-left: 20px;

        li {
            @apply mb-1;

            li {
                @apply mt-1;
            }
        }
    }
}

.check__icon {
    color: #0ba53f;
    margin-right: 8px;
    height: 16px;
    width: 16px;
}

.times__icon {
    color: #e50830;
    margin-right: 8px;
    height: 16px;
    width: 16px;
}

.spinner__icon {
    color: #000;
    margin-right: 8px;
    height: 16px;
    width: 16px;
}

.capture_error {
    .capture_tip_error {
        display: block;
    }
}
.capture_validated {
    @apply mb-2;
    .capture_tip_error {
        display: none;
    }
}

.successMessage,
.errorMessage,
.infoMessage {
    @apply mt-2 border rounded px-3 py-2 flex items-center text-sm;
}

.successMessage {
    background-color: #0ba53f26;
    border-color: #0ba53f1a;
}

.errorMessage {
    background-color: #e5083026;
    border-color: #e508301a;
}

.infoMessage {
    background-color: #d6d5dd;
    border-color: #6e6e91;
}

.authForm--V2 input:focus-visible {
    outline-color: #7a00e6;
}

input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button,
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
}
