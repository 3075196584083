.contentBlock__subtitle--bullet {
    position: relative;

    &:before {
        content: "\2022";
        position: relative;
        margin-right: 5px;
        bottom: 1px;
    }
}

.tableBorder {
    border-width: var(--theme-content-block-table-border-width);
    border-color: var(--theme-content-block-table-border-color);
    border-style: solid;
}

.contentBlock {
    .rich-text {
        table {
            @extend .tableBorder;
            width: var(--theme-content-block-table-width);

            @media (max-width: $width-desktop-min) {
                max-width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
                display: table;

                * {
                    word-break: normal;
                }
            }

            thead {
                color: var(--theme-content-block-thead-txt-color);
                background-color: var(--theme-content-block-thead-bg-color);
            }

            tbody {
                tr {
                    @extend .tableBorder;
                    background-color: var(--theme-content-block-cell-bg-color);
                    color: var(--theme-content-block-cell-txt-color);
                }
            }

            td,
            th {
                padding: var(--theme-content-block-cell-vertical-padding) var(--theme-content-block-cell-horizontal-padding);
            }
        }
    }
}
