#ot-sdk-cookie-policy {
    word-break: break-word;

    * {
        color: $color-grey-4e !important;
        line-height: 1.5rem !important;
    }

    p {
        font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
        font-size: 16px !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "DM Sans", Helvetica, Arial, sans-serif !important;
        font-weight: 500 !important;
    }
}
