.form {
    padding: 75px;
    border-radius: 3px;
    box-shadow: 2px 3px 12px 0px rgb(0 0 0 / 0.2);

    color: var(--theme-text, black);
    background: var(--theme-bg-default-color, white);

    * ::placeholder {
        color: var(--theme-text, black);
    }

    * .form-item-l {
        margin: 20px 0;
    }

    * h2 {
        text-align: center;
        font-weight: 500;
        font-size: 2.1rem;
        color: var(--theme-invert-bg);
    }

    * input {
        width: 100%;
        padding: 9px 15px;
        background: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: var(--theme-text, black);
        border-radius: 6px;
    }

    .button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        > input {
            width: auto !important;
            border-radius: 35px;
            padding: 10px 30px;
            background: var(--theme-invert-bg);
            color: var(--theme-invert-text);
            border-color: var(--theme-bg-default-color, black) !important;
        }
    }

    #message {
        height: 150px;
    }
}

.error {
    color: #f00;
    background-color: #fff;
}
