.accordion-title {
    font-family: var(--theme-accordion-font-family);
}

.accordionContainer {
    [dir="ltr"] & {
        .iconPlusMinus + .accordion-title.mr-3 {
            margin-right: 0;
        }

        .accordion-title + .iconPlusMinus.ltr\:mr-3 {
            margin-right: 0;
            margin-left: 0.75rem;
        }
    }
}
