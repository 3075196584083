a {
    color: inherit;
    text-decoration: none;
}

h1 {
    &.text-size-default {
        @apply text-title-1;
    }
}

h2 {
    &.text-size-default {
        @apply text-title-2;
    }
}

h3 {
    &.text-size-default {
        @apply text-title-3;
    }
}

h4 {
    &.text-size-default {
        @apply text-title-4;
    }
}

h5 {
    &.text-size-default {
        @apply text-title-5;
    }
}

h6 {
    &.text-size-default {
        @apply text-title-6;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: var(--theme-title-font-weight);
}
