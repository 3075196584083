/* #region HEADER */
.msw-header-com {
  background-color: var(--msw-header-com-mobile-backgroundcolor, transparent);
  border-bottom: var(--msw-header-com-border-bottom, solid 1px rgba(255, 255, 255, 0.25));
  height: calc((var(--msw-header-com-mobile-height, 64) / 16) *1rem);
  padding: var(--msw-header-com-mobile-padding, 0 calc((12 / 16) * 1rem));
}
.msw-header-com[data-msw-window-scroll='scrolled'] {
  height: calc((var(--msw-header-com-mobile-height-windowscrolled, var(--msw-header-com-mobile-height, 64)) / 16) * 1rem);
  padding: var(--msw-header-com-mobile-padding-windowscrolled, var(--msw-header-com-mobile-padding, 0 calc((12 / 16) * 1rem)));
  background-color: var(--msw-header-com-mobile-backgroundcolor-windowscrolled, var(--msw-header-com-mobile-backgroundcolor, rgba(82, 92, 163, 0.95)));
  border-bottom: var(--msw-header-com-border-bottom-windowscrolled, var(--msw-header-com-border-bottom, solid 1px rgba(255, 255, 255, 0.25)));
}
.msw-header-com,
.msw-header-com[data-msw-backgroundcolor-strength-initial-mobile="dark"],
.msw-header-com[data-msw-window-scroll="scrolled"],
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-mobile="dark"] {
  color: var(--msw-header-com-mobile-color--headerdark, var(--msw-header-com-color--headerdark, rgba(255, 255, 255, 1)));
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-mobile="light"],
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-mobile="light"] {
  color: var(--msw-header-com-mobile-color--headerlight, var(--msw-header-com-color--headerlight, rgba(82, 92, 163, 1)));
}
/* #endregion */
/* #region SITELOGO */
.msw-header-com__sitelogo-area {
}
.msw-header-com__sitelogo-area picture > img {
  height: calc((var(--msw-header-com-sitelogo-mobile-height, 40) / 16) * 1rem);
}
/* #endregion */
/* #region WELCOME MESSAGE */
.msw-header-com-welcome {
  color: currentColor;
  margin: 0 0 0 calc((var(--msw-header-com-welcome-mobile-marginleft, 16) / 16) * 1rem);
  padding: 0 0 0 calc((var(--msw-header-com-welcome-mobile-paddingleft, 16) / 16) * 1rem);
  border-left: var(--msw-header-com-welcome-borderleft, solid 1px currentColor);
  border-right: none 0;
}
[dir="rtl"] .msw-header-com-welcome {
  margin: 0 calc((var(--msw-header-com-welcome-mobile-marginleft, 16) / 16) * 1rem) 0 0;
  padding: 0 calc((var(--msw-header-com-welcome-mobile-paddingleft, 16) / 16) * 1rem) 0 0;
  border-right: var(--msw-header-com-welcome-borderleft, solid 1px currentColor);
  border-left: none 0;
}
.msw-header-com .msw-header-com-welcome,
.msw-header-com[data-msw-backgroundcolor-strength-initial-mobile="dark"] .msw-header-com-welcome,
.msw-header-com[data-msw-window-scroll="scrolled"] .msw-header-com-welcome,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-mobile="dark"] .msw-header-com-welcome {
  color: var(--msw-header-com-welcome-mobile-color--headerdark, var(--msw-header-com-mobile-color--headerdark, var(--msw-header-com-color--headerdark, rgba(255, 255, 255, 1))));
  border-color: var(--msw-header-com-welcome-mobile-borderleft-color--headerdark, rgba(255, 255, 255, 0.2))
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-mobile="light"] .msw-header-com-welcome,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-mobile="light"] .msw-header-com-welcome {
  color: var(--msw-header-com-welcome-mobile-color--headerlight, var(--msw-header-com-mobile-color--headerlight, var(--msw-header-com-color--headerlight, rgba(82, 92, 163, 1))));
  border-color: var(--msw-header-com-welcome-mobile-borderleft-color--headerlight, rgba(82, 92, 163, 0.2))
}
.msw-header-com-welcome__top {
  font-family: var(--msw-header-com-welcome-top-fontfamily, "sanofi-institutional-welcome-top", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-header-com-welcome-top-mobile-fontsize, 13) / 16) * 1rem);
  line-height: var(--msw-header-com-welcome-top-mobile-lineheight, inherit);
  font-weight: var(--msw-header-com-welcome-top-mobile-fontweight, bold);
}
.msw-header-com-welcome__bottom {
  font-family: var(--msw-header-com-welcome-bottom-fontfamily, "sanofi-institutional-welcome-bottom", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-header-com-welcome-bottom-mobile-fontsize, 13) / 16) * 1rem);
  line-height: var(--msw-header-com-welcome-bottom-mobile-lineheight, inherit);
  font-weight: var(--msw-header-com-welcome-bottom-mobile-fontweight, normal);
}
/* #endregion */
/* #region TOOLS - HEADER */
.msw-header-com .msw-header-com-tools {
  color: currentColor;
}
.msw-header-com .msw-header-com-tools,
.msw-header-com[data-msw-backgroundcolor-strength-initial-mobile="dark"] .msw-header-com-tools,
.msw-header-com[data-msw-window-scroll="scrolled"] .msw-header-com-tools,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-mobile="dark"] .msw-header-com-tools {
  color: var(--msw-header-com-tools-color--headerdark, var(--msw-header-com-color--headerdark, rgba(255, 255, 255, 1)));
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-mobile="light"] .msw-header-com-tools,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-mobile="light"] .msw-header-com-tools {
  color: var(--msw-header-com-tools-color--headerlight, var(--msw-header-com-color--headerlight, rgba(82, 92, 163, 1)));
}
.msw-header-com .msw-header-com-tool {
  margin: var(--msw-header-com-tool-margin, 0 calc((12 / 16) * 1rem));
}
.msw-header-com .msw-header-com-tool__icon {
    fill: currentColor;
    width: calc((var(--msw-header-com-icon-width, 28) / 16) * 1rem);
    height: calc((var(--msw-header-com-icon-height, 28) / 16) * 1rem);
}
.msw-header-com .msw-header-com-tool__link {

}
/* #endregion */
/* #region TOOLS - MOBILE NAV FOOTER */
.msw-header-com .msw-header-com__nav-footer .msw-header-com-tool {
  font-family: var(--msw-header-com-mobile-navfooter-tool-fontfamily, "sanofi-institutional-nav", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-header-com-mobile-navfooter-tool-fontsize, 14) / 16) * 1rem);
  font-weight: var(--msw-header-com-mobile-navfooter-tool-fontweight, bold);
  color: var(--msw-header-com-mobile-navfooter-tool-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1)));
  background-color: var(--msw-header-com-mobile-navfooter-tool-backgroundcolor, transparent);
  letter-spacing: calc((0.5 / 16) * 1rem);
  text-transform: var(--msw-header-com-mobile-navfooter-tool-texttransform, uppercase);
  line-height: calc((var(--msw-header-com-mobile-navfooter-tool-lineheight, 14) / 16) * 1rem);
  padding: var(--msw-header-com-mobile-navfooter-tool-padding, 0 calc((10 / 16) * 1rem));
  margin: var(--msw-header-com-mobile-navfooter-tool-margin, 0);
  border-bottom: var(--msw-header-com-mobile-navfooter-tool-borderbottom, var(--msw-navigation-mobile-level1-item-borderbottom, solid 1px rgba(244, 247, 252, 1)));
}
.msw-header-com .msw-header-com__nav-footer .msw-header-com-tool__link {
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* min-height: calc((var(--msw-header-com-mobile-navfooter-toollink-minheight, var(--msw-header-com-mobile-navfooter-toollink-height, var(--msw-navigation-mobile-level1-itemlink-height, 60))) / 16) * 1rem); */
  --msw-temp-header-com-mobile-navfooter-toollink-minheight: var(--msw-header-com-mobile-navfooter-toollink-minheight, var(--msw-header-com-mobile-navfooter-toollink-height, var(--msw-navigation-mobile-level1-itemlink-height, 60)));
  min-height: calc((var(--msw-temp-header-com-mobile-navfooter-toollink-minheight) / 16) * 1rem);
  height: calc((var(--msw-header-com-mobile-navfooter-toollink-height, var(--msw-navigation-mobile-level1-itemlink-height, 60)) / 16) * 1rem);
  color: currentColor;
  padding: var(--msw-header-com-mobile-navfooter-toollink-padding, 0 calc((5 / 16) * 1rem));
}
.msw-header-com .msw-header-com__nav-footer .msw-header-com-tool__text {
}
.msw-header-com .msw-header-com__nav-footer .msw-header-com-tool__icon {
  fill: currentColor;
  width: calc((var(--msw-header-com-icon-width, 28) / 16) * 1rem);
  height: calc((var(--msw-header-com-icon-height, 28) / 16) * 1rem);
}
/* #endregion */
/* #region TOOLS - MOBILE PANEL */
.msw-header-com-mobilepanel--close,
.msw-header-com-mobilepanel--open {
  margin: 0 0 0 calc((var(--msw-header-com-navcontainer-toggle-marginleft, 12) / 16) * 1rem);
  cursor: pointer;
}
[dir="rtl"] .msw-header-com-mobilepanel--close,
[dir="rtl"] .msw-header-com-mobilepanel--open {
  margin: 0 calc((var(--msw-header-com-navcontainer-toggle-marginleft, 12) / 16) * 1rem) 0 0;
}
.msw-header-com__nav-container {
  background-color: var(--msw-header-com-navcontainer-mobile-backgroundcolor, rgba(255, 255, 255, 1));
  z-index: 100;
}
.msw-header-com__nav-header {
  background-color: var(--msw-header-com-navcontainer-header-mobile-backgroundcolor, var(--msw-header-com-mobile-backgroundcolor, rgba(82, 92, 163, 1)));
  color: var(--msw-header-com-navcontainer-header-mobile-color, rgba(255, 255, 255, 1));
  min-height: calc((var(--msw-header-com-navcontainer-header-mobile-height, var(--msw-header-com-mobile-height, 64)) / 16) * 1rem);
  height: calc((var(--msw-header-com-navcontainer-header-mobile-height, var(--msw-header-com-mobile-height, 64)) / 16) * 1rem);
  padding: var(--msw-header-com-mobile-padding, 0 calc((12 / 16) * 1rem));
  border-bottom: solid 1px var(--msw-header-com-navcontainer-header-mobile-borderbottom-color, transparent)
}
.msw-header-com__nav-menu {
}
.msw-header-com .msw-header-com__nav-footer {
  min-height: calc((var(--msw-header-com-navcontainer-footer-mobile-height, var(--msw-header-com-mobile-height, 64)) / 16) * 1rem);
  height: calc((var(--msw-header-com-navcontainer-footer-mobile-height, var(--msw-header-com-mobile-height, 64)) / 16) * 1rem);
  border-top: solid 1px var(--msw-header-com-navcontainer-footer-mobile-bordertop-color, rgba(244, 247, 252, 1));
}
/* #endregion */
/* #region LANGUAGE SWITCH */
.msw-header-com .msw-header-com-languageswitch {
  font-family: var(--msw-header-com-languageswitch-fontfamily, "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-header-com-languageswitch-fontsize, 16) / 16) * 1rem);
}
.msw-header-com .msw-header-com-languageswitch--mobile {}
.msw-header-com .msw-header-com-languageswitch--mobile .msw-header-com-languageswitch__selector {}
.msw-header-com .msw-header-com-languageswitch--mobile .msw-header-com-languageswitch__language {
  margin: 0 0 0 calc((var(--msw-header-com-languageswitch-language-gap, 16) / 16) * 1rem);
  padding: var(--msw-header-com-languageswitch-language-padding, 0);
  background-color: var(--msw-header-com-languageswitch-language-backgroundcolor, transparent);
  font-weight: var(--msw-header-com-languageswitch-language-fontweight, normal);
  height: var(--msw-header-com-languageswitch-mobile-language-height, auto);
  padding: var(--msw-header-com-languageswitch-mobile-language-padding, 0);
}
.msw-header-com .msw-header-com-languageswitch--mobile .msw-header-com-languageswitch__language:hover {
  background-color: var(--msw-header-com-languageswitch-mobile-language-focus-backgroundcolor, transparent);
  color: var(--msw-header-com-languageswitch-mobile-language-focus-color, currentColor);
}
[dir="rtl"] .msw-header-com .msw-header-com-languageswitch--mobile .msw-header-com-languageswitch__language {
  margin: 0 calc((var(--msw-header-com-languageswitch-language-gap, 16) / 16) * 1rem) 0 0;
}
.msw-header-com .msw-header-com-languageswitch--mobile .msw-header-com-languageswitch__language:first-of-type {
  margin: 0;
}
.msw-header-com .msw-header-com-languageswitch--mobile .msw-header-com-languageswitch__language--current {
  background-color: var(--msw-header-com-languageswitch-language-current-backgroundcolor, transparent);
  font-weight: var(--msw-header-com-languageswitch-language-current-fontweight, bold);
}
.msw-header-com .msw-header-com-languageswitch--mobile .msw-header-com-languageswitch__language a {
  text-decoration: var(--msw-header-com-languageswitch-languagelink-textdecoration, underline);
}
/* #endregion */

@media (min-width: 1280px) {
  /* #region HEADER - DESKTOP */
  .msw-header-com {
    height: calc((var(--msw-header-com-desktop-height, 112) / 16) * 1rem);
    padding: var(--msw-header-com-desktop-padding, 0 calc((40 / 16) * 1rem));
    background-color: var(--msw-header-com-desktop-backgroundcolor, transparent);
  }
  .msw-header-com[data-msw-window-scroll='scrolled'] {
    height: calc((var(--msw-header-com-desktop-height-windowscrolled, var(--msw-header-com-desktop-height, 64)) / 16) * 1rem);
    padding: var(--msw-header-com-desktop-padding-windowscrolled, var(--msw-header-com-desktop-padding, 0 calc((20 / 16) * 1rem)));
    background-color: var(--msw-header-com-desktop-backgroundcolor-windowscrolled, var(--msw-header-com-desktop-backgroundcolor, rgba(82, 92, 163, 0.95)));
  }
  .msw-header-com[data-msw-navigation-state='active'] {
    background-color: var(--msw-header-com-desktop-backgroundcolor-navigationactive, var(--msw-header-com-desktop-backgroundcolor, rgba(255, 255, 255, 1)));
    border-bottom: var(--msw-header-com-border-bottom-navigationactive, var(--msw-header-com-border-bottom, solid 1px rgba(82, 92, 163, 0.25)));
  }
  .msw-header-com,
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"],
  .msw-header-com[data-msw-window-scroll="scrolled"],
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"],
  .msw-header-com[data-msw-navigation-state="active"],
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] {
    color: var(--msw-header-com-desktop-color--headerdark, var(--msw-header-com-color--headerdark, rgba(255, 255, 255, 1)));
  }
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"],
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"],
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] {
    color: var(--msw-header-com-desktop-color--headerlight, var(--msw-header-com-color--headerlight, rgba(82, 92, 163, 1)));
  }
  /* #endregion */
  /* #region SITELOGO - DESKTOP */
  .msw-header-com__sitelogo-area {
  }
  .msw-header-com__sitelogo-area picture > img {
    height: var(--msw-header-com-sitelogo-desktop-height, 100%);
  }
  .msw-header-com[data-msw-window-scroll='scrolled'] .msw-header-com__sitelogo-area {
    --tw-scale-x: var(--msw-header-com-sitelogo-area-transform-scale, 0.75);
    --tw-scale-y: var(--msw-header-com-sitelogo-area-transform-scale, 0.75);
  }
  /* #endregion */
  /* #region WELCOME MESSAGE - DESKTOP */
  .msw-header-com-welcome {
    margin: 0 0 0 calc((var(--msw-header-com-welcome-desktop-marginleft, 24) / 16) * 1rem);
    padding: 0 0 0 calc((var(--msw-header-com-welcome-desktop-paddingleft, 20) / 16) * 1rem);
  }
  [dir="rtl"] .msw-header-com-welcome {
    margin: 0 calc((var(--msw-header-com-welcome-desktop-marginleft, 24) / 16) * 1rem) 0 0;
    padding: 0 calc((var(--msw-header-com-welcome-desktop-paddingleft, 20) / 16) * 1rem) 0 0;
  }
  .msw-header-com .msw-header-com-welcome,
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] .msw-header-com-welcome,
  .msw-header-com[data-msw-window-scroll="scrolled"] .msw-header-com-welcome,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] .msw-header-com-welcome,
  .msw-header-com[data-msw-navigation-state="active"] .msw-header-com-welcome,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] .msw-header-com-welcome {
    color: var(--msw-header-com-welcome-desktop-color--headerdark, var(--msw-header-com-desktop-color--headerdark, var(--msw-header-com-color--headerdark, rgba(255, 255, 255, 1))));
    border-color: var(--msw-header-com-welcome-desktop-borderleft-color--headerdark, rgba(255, 255, 255, 0.2));
  }
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] .msw-header-com-welcome,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] .msw-header-com-welcome,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] .msw-header-com-welcome {
    color: var(--msw-header-com-welcome-desktop-color--headerlight, var(--msw-header-com-desktop-color--headerlight, var(--msw-header-com-color--headerlight, rgba(82, 92, 163, 1))));
    border-color: var(--msw-header-com-welcome-desktop-borderleft-color--headerlight, rgba(82, 92, 163, 0.2));
  }
  .msw-header-com-welcome__top {
    font-size: calc((var(--msw-header-com-welcome-top-desktop-fontsize, 17) / 16) * 1rem);
    line-height: var(--msw-header-com-welcome-top-desktop-lineheight, inherit);
    font-weight: var(--msw-header-com-welcome-top-desktop-fontweight, bold);
  }
  .msw-header-com-welcome__bottom {
    font-size: calc((var(--msw-header-com-welcome-bottom-desktop-fontsize, 17) / 16) * 1rem);
    line-height: var(--msw-header-com-welcome-bottom-desktop-lineheight, inherit);
    font-weight: var(--msw-header-com-welcome-bottom-desktop-fontweight, normal);
  }
  /* #endregion */
  /* #region TOOLS - DESKTOP */
  .msw-header-com__tools {
  }
  .msw-header-com .msw-header-com__tools,
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] .msw-header-com__tools,
  .msw-header-com[data-msw-window-scroll="scrolled"] .msw-header-com__tools,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] .msw-header-com__tools,
  .msw-header-com[data-msw-navigation-state="active"] .msw-header-com__tools,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] .msw-header-com__tools {
    color: var(--msw-header-com-tools-color--headerdark, var(--msw-header-com-color--headerdark, rgba(255, 255, 255, 1)));
  }
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] .msw-header-com__tools,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] .msw-header-com__tools,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] .msw-header-com__tools {
    color: var(--msw-header-com-tools-color--headerlight, var(--msw-header-com-color--headerlight, rgba(82, 92, 163, 1)));
  }
  /* #endregion */
  /* #region NAVIGATION CONTAINER - DESKTOP */
  .msw-header-com__nav-container {
    background-color: var(--msw-header-com-navcontainer-desktop-backgroundcolor, transparent);
  }
  /* #endregion */
  /* #region LANGUAGE SWITCH - DESKTOP */
  .msw-header-com .msw-header-com-languageswitch--desktop {}
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button {
    padding: calc((var(--msw-header-com-languageswitch-desktop-padding-vertical, 6) / 16) * 1rem) calc((var(--msw-header-com-languageswitch-desktop-padding-horizontal, 12) / 16) * 1rem);
    border: var(--msw-header-com-languageswitch-desktop-border, solid 1px transparent);
    border-radius: calc((var(--msw-header-com-languageswitch-desktop-borderradius, 4) / 16) * 1rem);
  }
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com[data-msw-window-scroll="scrolled"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com[data-msw-navigation-state="active"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open,
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open,
  .msw-header-com[data-msw-window-scroll="scrolled"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open,
  .msw-header-com[data-msw-navigation-state="active"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open {
    border-color: var(--msw-header-com-languageswitch-desktop-bordercolor--headerdark, var(--msw-header-com-desktop-color--headerdark, var(--msw-header-com-color--headerdark, rgba(255, 255, 255, 1))));
  }
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button:hover,
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button.msw-header-com-languageswitch__button--open {
    border-color: var(--msw-header-com-languageswitch-desktop-bordercolor--headerlight, var(--msw-header-com-desktop-color--headerlight, var(--msw-header-com-color--headerlight, rgba(82, 92, 163, 1))));
  }
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button-icon {
    width: calc((var(--msw-header-com-languageswitch-desktop-button-icon-width, 16) / 16) * 1rem);
    height: calc((var(--msw-header-com-languageswitch-desktop-button-icon-height, 16) / 16) * 1rem);
    margin: 0 0 0 calc((var(--msw-header-com-languageswitch-desktop-padding-vertical, 6) / 16) * 1rem);
  }
  [dir="rtl"] .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__button-icon {
    margin: 0 calc((var(--msw-header-com-languageswitch-desktop-padding-vertical, 6) / 16) * 1rem) 0 0;
  }
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector {
    background-color: var(--msw-header-com-languageswitch-desktop-selector-backgroundcolor, rgba(255, 255, 255, 1));
    border: var(--msw-header-com-languageswitch-desktop-border, solid 1px transparent);
    border-bottom-left-radius: calc((var(--msw-header-com-languageswitch-desktop-borderradius, 4) / 16) * 1rem);
    border-bottom-right-radius: calc((var(--msw-header-com-languageswitch-desktop-borderradius, 4) / 16) * 1rem);
    border-top: 0 none;
    z-index: 100;
  }
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-window-scroll="scrolled"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-navigation-state="active"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector {
    border-color: var(--msw-header-com-languageswitch-desktop-bordercolor--headerdark, var(--msw-header-com-desktop-color--headerdark, var(--msw-header-com-color--headerdark, rgba(255, 255, 255, 1))));
  }
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector,
  .msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__selector {
    border-color: var(--msw-header-com-languageswitch-desktop-bordercolor--headerlight, var(--msw-header-com-desktop-color--headerlight, var(--msw-header-com-color--headerlight, rgba(82, 92, 163, 1))));
  }
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__language > a,
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__language > span {
    padding: calc((var(--msw-header-com-languageswitch-desktop-padding-vertical, 6) / 16) * 1rem) calc((var(--msw-header-com-languageswitch-desktop-padding-horizontal, 12) / 16) * 1rem);
    background-color: var(--msw-header-com-languageswitch-desktop-language-backgroundcolor, transparent);
    color: var(--msw-header-com-languageswitch-desktop-language-color, rgba(82, 92, 163, 1));
  }
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__language > a:hover,
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__language > span:hover,
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__language > a:focus,
  .msw-header-com .msw-header-com-languageswitch--desktop .msw-header-com-languageswitch__language > span:focus {
    background-color: var(--msw-header-com-languageswitch-desktop-language-focus-backgroundcolor, rgba(82, 92, 163, 0.1));
    color: var(--msw-header-com-languageswitch-desktop-language-focus-color, rgba(82, 92, 163, 1));
  }
  /* #endregion */
}

/* #region NAVIGATION - GLOBAL */
.msw-header-com nav.msw-nav:not(.msw-initialized) {
  display: none;
}
/* #endregion */

/* #region NAVIGATION - DESKTOP */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] {
  width: 100%;
  overflow-x: hidden;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level-opened {
  display: block;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level-closed {
  display: none;
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 1 */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc((var(--msw-navigation-desktop-level1-list-height ,60) / 16) * 1rem);
}
.msw-header-com[data-msw-window-scroll='scrolled'] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 {
  height: calc((var(--msw-navigation-desktop-level1-list-height--windowscrolled, var(--msw-navigation-desktop-level1-list-height, 60)) / 16) * 1rem);
}
/* #region NAVIGATION - DESKTOP - LEVEL 1 - LIST BACKGROUND-COLOR */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 {
  background-color: var(--msw-navigation-desktop-level1-list-backgroundcolor--headerdark, transparent);
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 {
  background-color: var(--msw-navigation-desktop-level1-list-backgroundcolor--headerlight, transparent);
}
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 {
  background-color: var(--msw-navigation-desktop-level1-list-backgroundcolor--headerdark, transparent);
}
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 {
  background-color: var(--msw-navigation-desktop-level1-list-backgroundcolor--headerlight, transparent);
}
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 {
  background-color: var(--msw-navigation-desktop-level1-list-backgroundcolor--headerdark, transparent);
}
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 {
  background-color: var(--msw-navigation-desktop-level1-list-backgroundcolor--headerlight, transparent);
}
/* #endregion */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item {
  font-family: var(--msw-navigation-desktop-level1-item-fontfamily, "sanofi-institutional-nav", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-navigation-desktop-level1-item-fontsize, 13) / 16) * 1rem);
  font-weight: var(--msw-navigation-desktop-level1-item-fontweight, bold);
  color: var(--msw-navigation-desktop-level1-item-color--headerdark, rgba(255, 255, 255, 1));
  background-color: var(--msw-navigation-desktop-level1-item-backgroundcolor--headerdark, transparent);
  letter-spacing: calc((0.5 / 16) * 1rem);
  text-transform: var(--msw-navigation-desktop-level1-item-texttransform, uppercase);
  list-style: none;
  line-height: calc((var(--msw-navigation-desktop-level1-item-lineheight, 13) / 16) * 1rem);
  padding: var(--msw-navigation-desktop-level1-item-padding, 0);
  margin: var(--msw-navigation-desktop-level1-item-margin, 0 calc((10 / 16) * 1rem));
  flex: 0 1 auto;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link {
  display: flex;
  align-items: center;
  height: calc((var(--msw-navigation-desktop-level1-itemlink-height, 45) / 16) * 1rem);
  text-align: center;
  padding: var(--msw-navigation-desktop-level1-itemlink-padding, 0 calc((5 / 16) * 1rem));
  color: currentColor;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link > span {
  white-space: pre-line;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > .msw-nav-item-childrenlink {
  display: none;
}
/* #region NAVIGATION - DESKTOP - LEVEL 1 - ITEM COLOR / BACKGROUND-COLOR */
/* #region NAVIGATION - DESKTOP - LEVEL 1 - ITEM COLOR / BACKGROUND-COLOR - Item */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item {
  color: var(--msw-navigation-desktop-level1-item-color--headerdark, rgba(255, 255, 255, 1));
  background-color: var(--msw-navigation-desktop-level1-item-backgroundcolor--headerdark, transparent);
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item {
  color: var(--msw-navigation-desktop-level1-item-color--headerlight, rgba(82, 92, 163, 1));
  background-color: var(--msw-navigation-desktop-level1-item-backgroundcolor--headerlight, transparent);
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 1 - ITEM COLOR / BACKGROUND-COLOR - Item selected (and selected path) or current (and current path) */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath {
  color: var(--msw-navigation-desktop-level1-item-selectedcurrent-color--headerdark, rgba(255, 255, 255, 1));
  background-color: var(--msw-navigation-desktop-level1-item-selectedcurrent-backgroundcolor--headerdark, transparent);
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath {
  color: var(--msw-navigation-desktop-level1-item-selectedcurrent-color--headerlight, rgba(82, 92, 163, 1));
  background-color: var(--msw-navigation-desktop-level1-item-selectedcurrent-backgroundcolor--headerlight, transparent);
}
/* #endregion */
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 1 - ITEM-LINK BORDER */
/* #region NAVIGATION - DESKTOP - LEVEL 1 - ITEM-LINK BORDER - Item-Link */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link {
  border-top: var(--msw-navigation-desktop-level1-itemlink-bordertop--headerdark, none 0 currentColor);
  border-right: var(--msw-navigation-desktop-level1-itemlink-borderright--headerdark, none 0 currentColor);
  border-bottom: var(--msw-navigation-desktop-level1-itemlink-borderbottom--headerdark, solid 2px transparent);
  border-left: var(--msw-navigation-desktop-level1-itemlink-borderleft--headerdark, none 0 currentColor);
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link {
  border-top: var(--msw-navigation-desktop-level1-itemlink-bordertop--headerlight, none 0 currentColor);
  border-right: var(--msw-navigation-desktop-level1-itemlink-borderright--headerlight, none 0 currentColor);
  border-bottom: var(--msw-navigation-desktop-level1-itemlink-borderbottom--headerlight, solid 2px transparent);
  border-left: var(--msw-navigation-desktop-level1-itemlink-borderleft--headerlight, none 0 currentColor);
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 1 - ITEM-LINK BORDER - Item-Link selected (and selected path) or current (and current path) */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link {
  border-top: var(--msw-navigation-desktop-level1-itemlink-selectedcurrent-bordertop--headerdark, none 0 currentColor);
  border-right: var(--msw-navigation-desktop-level1-itemlink-selectedcurrent-borderright--headerdark, none 0 currentColor);
  border-bottom: var(--msw-navigation-desktop-level1-itemlink-selectedcurrent-borderbottom--headerdark, solid 2px rgba(255, 255, 255, 1));
  border-left: var(--msw-navigation-desktop-level1-itemlink-selectedcurrent-borderleft--headerdark, none 0 currentColor);
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-current > a.msw-nav-item-link,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-currentpath > a.msw-nav-item-link {
  border-top: var(--msw-navigation-desktop-level1-itemlink-selectedcurrent-bordertop--headerlight, none 0 currentColor);
  border-right: var(--msw-navigation-desktop-level1-itemlink-selectedcurrent-borderright--headerlight, none 0 currentColor);
  border-bottom: var(--msw-navigation-desktop-level1-itemlink-selectedcurrent-borderbottom--headerlight, solid 2px rgba(82, 92, 163, 1));
  border-left: var(--msw-navigation-desktop-level1-itemlink-selectedcurrent-borderleft--headerlight, none 0 currentColor);
}
/* #endregion */
/* #endregion */
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 1 Divider */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider {
  font-family: var(--msw-navigation-desktop-level1-divider-fontfamily, "sanofi-institutional-nav", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-navigation-desktop-level1-divider-fontsize, 13) / 16) * 1rem);
  font-weight: var(--msw-navigation-desktop-level1-divider-fontweight, bold);
  color: var(--msw-navigation-desktop-level1-divider-color--headerdark, rgba(255, 255, 255, 1));
  letter-spacing: calc((0.5 / 16) * 1rem);
  text-transform: var(--msw-navigation-desktop-level1-divider-texttransform, uppercase);
  list-style: none;
  line-height: calc((var(--msw-navigation-desktop-level1-divider-lineheight, 13) / 16) * 1rem);
  padding: 0;
  margin: 0px calc((10 / 16) * 1rem);
  flex: 0 1 auto;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider,
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider,
.msw-header-com[data-msw-window-scroll="scrolled"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider,
.msw-header-com[data-msw-navigation-state="active"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="dark"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider {
  color: var(--msw-navigation-desktop-level1-divider-color--headerdark, rgba(255, 255, 255, 1));
}
.msw-header-com[data-msw-backgroundcolor-strength-initial-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider,
.msw-header-com[data-msw-window-scroll="scrolled"][data-msw-backgroundcolor-strength-windowscrolled-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider,
.msw-header-com[data-msw-navigation-state="active"][data-msw-backgroundcolor-strength-navigationactive-desktop="light"] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider {
  color: var(--msw-navigation-desktop-level1-divider-color--headerlight, rgba(82, 92, 163, 1));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level1 > li.msw-nav-item-divider span {
  display: flex;
  align-items: center;
  height: calc((var(--msw-navigation-desktop-level1-divider-height, 45) / 16) * 1rem);
  border-top: var(--msw-navigation-desktop-level1-divider-bordertop, none 0 currentColor);
  border-right: var(--msw-navigation-desktop-level1-divider-borderright, none 0 currentColor);
  border-bottom: var(--msw-navigation-desktop-level1-divider-borderbottom, solid 2px transparent);
  border-left: var(--msw-navigation-desktop-level1-divider-borderleft, none 0 currentColor);
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 2+ */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) {
  position: absolute;
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem));
  height: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  margin-left: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  flex: 0 0 100%;
  order: 3;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) {
	margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  right: 0;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item > .msw-nav-item-childrenlink {
  text-align: center;
  /* right: calc(calc((10 / 16) * 1rem) + 0%); */
  transition: all 0.5s ease;
  /* min-width: calc((var(--msw-navigation-desktop-levels-itemlinksub-width, 30) / 16) * 1rem); */
  width: calc((var(--msw-navigation-desktop-levels-linksub-width, 30) / 16) * 1rem);
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* height: calc((var(--msw-navigation-desktop-levels-linksub-height, var(--msw-navigation-desktop-levels-itemlink-height, var(--msw-navigation-desktop-level1-list-height, 60))) / 16) * 1rem); */
  --msw-temp-navigation-desktop-levels-linksub-height: var(--msw-navigation-desktop-levels-linksub-height, var(--msw-navigation-desktop-levels-itemlink-height, var(--msw-navigation-desktop-level1-list-height, 60)));
  height: calc((var(--msw-temp-navigation-desktop-levels-linksub-height) / 16) * 1rem);
  /* padding: calc((5 / 16) * 1rem) 0 calc((5 / 16) * 1rem) 0; */
  align-self: center;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  order: 2;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item > .msw-nav-item-childrenlink {
  /* right: auto;
	left: calc(calc((10 / 16) * 1rem) + 0%); */
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-title {
  font-family: var(--msw-navigation-desktop-levels-listtitle-fontfamily, "sanofi-institutional-nav-title", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-navigation-desktop-levels-listtitle-fontsize, 21.9) / 16) * 1rem);
  font-weight: var(--msw-navigation-desktop-levels-listtitle-fontweight, normal);
  color: var(--msw-navigation-desktop-levels-listtitle-color, rgba(82, 92, 163, 1));
  list-style: none;
  text-transform: var(--msw-navigation-desktop-levels-listtitle-texttransform, none);
  line-height: calc((var(--msw-navigation-desktop-levels-listtitle-lineheight, 21.9) / 16) * 1rem);
  padding: var(--msw-navigation-desktop-levels-listtitle-padding, calc((20 / 16) * 1rem) calc((45 / 16) * 1rem));
  margin-right: 0;
  margin-left: 0;
  width: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  min-height: var(--msw-navigation-desktop-levels-listtitle-minheight, 60);
  display: flex;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-title > span {
  cursor: pointer;
  align-self: center;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-title > button.msw-nav-title-levelup {
  display: none;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item {
  display: flex;
  font-family: var(--msw-navigation-desktop-levels-item-fontfamily, "sanofi-institutional-nav", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-navigation-desktop-levels-item-fontsize, 13) / 16) * 1rem);
  font-weight: var(--msw-navigation-desktop-levels-item-fontweight, bold);
  color: var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1));
  letter-spacing: calc((0.5 / 16) * 1rem);
  text-transform: var(--msw-navigation-desktop-levels-item-texttransform, none);
  list-style: none;
  line-height: calc((var(--msw-navigation-desktop-levels-item-lineheight, 13) / 16) * 1rem);
  padding: 0;
  margin: 0;
  width: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  overflow-x: hidden;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item > a.msw-nav-item-link {
  display: flex;
  align-items: center;
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* min-height: calc((var(--msw-navigation-desktop-levels-itemlink-minheight, var(--msw-navigation-desktop-levels-itemlink-height, var(--msw-navigation-desktop-level1-list-height, 60))) / 16) * 1rem); */
  --msw-temp-navigation-desktop-levels-itemlink-minheight: var(--msw-navigation-desktop-levels-itemlink-minheight, var(--msw-navigation-desktop-levels-itemlink-height, var(--msw-navigation-desktop-level1-list-height, 60)));
  min-height: calc((var(--msw-temp-navigation-desktop-levels-itemlink-minheight) / 16) * 1rem);
  height: calc((var(--msw-navigation-desktop-levels-itemlink-height, var(--msw-navigation-desktop-level1-list-height, 60)) / 16) * 1rem);
  color: currentColor;
  text-align: left;
  padding: var(--msw-navigation-desktop-levels-itemlink-padding, calc((18.5 / 16) * 1rem) calc((45 / 16) * 1rem));
  width: 100%;
  position: relative;
  flex: 0 1 auto;
  width: calc(100% - calc((var(--msw-navigation-desktop-levels-linksub-width, 30) / 16) * 1rem));
  order: 1;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item > a.msw-nav-item-link {
	text-align: right;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item > a.msw-nav-item-link > span {
  display: inline-block;
  line-height: calc((var(--msw-navigation-desktop-levels-itemlink-text-lineheight, 18) / 16) * 1rem);
  width: calc(calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem) - calc((var(--msw-navigation-desktop-levels-itemlink-text-indent, 90) / 16) * 1rem));
  min-width: calc(calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem) - calc((var(--msw-navigation-desktop-levels-itemlink-text-indent, 90) / 16) * 1rem));
  white-space: pre-line;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item.msw-nav-item-selectedpath,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link {
  color: var(--msw-navigation-desktop-levels-item-selected-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link > span,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul:not(.msw-nav-level1) > li.msw-nav-item.msw-nav-item-selectedpath > a.msw-nav-item-link > span {
  text-decoration: var(--msw-navigation-desktop-levels-itemlink-selected-textdecoration, underline solid currentColor);
  text-underline-position: under;
  text-underline-offset: var(--msw-navigation-desktop-levels-itemlink-selected-underline-offset, auto);
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 2 Specific */
 .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 {
  width: 100%;
  height: auto;
  background-color: var(--msw-navigation-desktop-level2-list-backgroundcolor, var(--msw-navigation-desktop-levels-list-backgroundcolor, rgba(254, 254, 254, 1)));
  top: auto;
  left: 0;
  margin-left: 0;
  margin-top: calc((((var(--msw-navigation-header-desktop-height, var(--msw-header-com-desktop-height, 112)) - var(--msw-navigation-desktop-level1-itemlink-height, 45)) / 2) / 16) * 1rem);
  box-shadow: var(--msw-navigation-desktop-level2-list-boxshadow, 0px 1px 1px 0 rgba(0, 0, 0, 0.08));
  overflow-x: hidden;
  z-index: 2;
  flex: none;
  order: unset;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 {
	margin-right: 0;
}
.msw-header-com[data-msw-window-scroll='scrolled'] nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 {
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* margin-top: calc((((var(--msw-navigation-header-desktop-height-scroll, var(--msw-header-com-desktop-height-windowscrolled, var(--msw-header-com-desktop-height, 64))) - var(--msw-navigation-desktop-level1-itemlink-height, 45)) / 2) / 16) * 1rem); */
  --msw-temp-navigation-header-desktop-height-scroll: var(--msw-navigation-header-desktop-height-scroll, var(--msw-header-com-desktop-height-windowscrolled, var(--msw-header-com-desktop-height, 64)));
  margin-top: calc((((var(--msw-temp-navigation-header-desktop-height-scroll) - var(--msw-navigation-desktop-level1-itemlink-height, 45)) / 2) / 16) * 1rem);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 > li.msw-nav-title {
  color: var(--msw-navigation-desktop-level2-listtitle-color, var(--msw-navigation-desktop-levels-listtitle-color, rgba(82, 92, 163, 1)));
  margin-left: calc((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2);
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 > li.msw-nav-title {
	margin-left: 0;
	margin-right: calc((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 > li.msw-nav-item {
  text-transform: var(--msw-navigation-desktop-level2-item-texttransform, var(--msw-navigation-desktop-levels-item-texttransform, uppercase));
  color: var(--msw-navigation-desktop-level2-item-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1)));
  margin-left: calc((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2);
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 > li.msw-nav-item {
	margin-left: 0;
	margin-right: calc((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 > li.msw-nav-item.msw-nav-item-selectedpath {
  background-color: var(--msw-navigation-desktop-level2-item-selected-backgroundcolor, var(--msw-navigation-desktop-levels-item-selected-backgroundcolor, rgba(244, 247, 252, 1)));
  color: var(--msw-navigation-desktop-level2-item-selected-color, var(--msw-navigation-desktop-levels-item-selected-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1))));
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 3 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level3 {
  width: calc(100% - (((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2) + calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem)));
  background-color:var(--msw-navigation-desktop-level3-list-backgroundcolor, var(--msw-navigation-desktop-levels-list-backgroundcolor, rgba(244, 247, 252, 1)));
  z-index: 3;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-5 ul.msw-nav-level3 {
  width: calc(100% - (((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2) + calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem)));
  margin-left: calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-5 ul.msw-nav-level3 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-5) ul.msw-nav-level3 {
  width: calc(100% - (((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2) + calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem)));
  margin-left: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-5) ul.msw-nav-level3 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level3 {
  width: calc(100% - (((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2) + calc((var(--msw-navigation-desktop-levels-width3, 100) / 16) * 1rem)));
  margin-left: calc((var(--msw-navigation-desktop-levels-width3, 100) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level3 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width3, 100) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-5):not(.msw-nav-leveldisplayed-6) ul.msw-nav-level3 {
  width: calc(100% - (((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2) + calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem)));
  margin-left: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-5):not(.msw-nav-leveldisplayed-6) ul.msw-nav-level3 {
  margin-left: 0;
  margin-right: calc((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2 + calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem));
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level3 > li.msw-nav-title {
  color: var(--msw-navigation-desktop-level3-listtitle-color, var(--msw-navigation-desktop-levels-listtitle-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level3 > li.msw-nav-item {
  text-transform: var(--msw-navigation-desktop-level3-item-texttransform, var(--msw-navigation-desktop-levels-item-texttransform, uppercase));
  color: var(--msw-navigation-desktop-level3-item-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level3 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level3 > li.msw-nav-item.msw-nav-item-selectedpath {
  background-color: var(--msw-navigation-desktop-level3-item-selected-backgroundcolor, var(--msw-navigation-desktop-levels-item-selected-backgroundcolor, rgba(229, 237, 250, 1)));
  color: var(--msw-navigation-desktop-level3-item-selected-color, var(--msw-navigation-desktop-levels-item-selected-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1))));
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 4 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level4 {
  background-color: var(--msw-navigation-desktop-level4-list-backgroundcolor, var(--msw-navigation-desktop-levels-list-backgroundcolor, rgba(229, 237, 250, 1)));
  z-index: 4;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-5 ul.msw-nav-level4 {
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem));
  margin-left: calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-5 ul.msw-nav-level4 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level4 {
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width3, 100) / 16) * 1rem));
  margin-left: calc((var(--msw-navigation-desktop-levels-width3, 100) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level4 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width3, 100) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-5) ul.msw-nav-level4 {
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem));
  margin-left: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-5) ul.msw-nav-level4 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-5):not(.msw-nav-leveldisplayed-6) ul.msw-nav-level4 {
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem));
  margin-left: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-5):not(.msw-nav-leveldisplayed-6) ul.msw-nav-level4 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level4 > li.msw-nav-title {
  color: var(--msw-navigation-desktop-level4-listtitle-color, var(--msw-navigation-desktop-levels-listtitle-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level4 > li.msw-nav-item {
  text-transform: var(--msw-navigation-desktop-level4-item-texttransform, var(--msw-navigation-desktop-levels-item-texttransform, uppercase));
  color: var(--msw-navigation-desktop-level4-item-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level4 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level4 > li.msw-nav-item.msw-nav-item-selectedpath {
  background-color: var(--msw-navigation-desktop-level4-item-selected-backgroundcolor, var(--msw-navigation-desktop-levels-item-selected-backgroundcolor, rgba(219, 230, 248, 1)));
  color: var(--msw-navigation-desktop-level4-item-selected-color, var(--msw-navigation-desktop-levels-item-selected-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1))));
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 5 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level5.msw-nav-level-opened {
  display: none;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-5 ul.msw-nav-level5.msw-nav-level-opened {
  display: block;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level5 {
  background-color: var(--msw-navigation-desktop-level5-list-backgroundcolor, var(--msw-navigation-desktop-levels-list-backgroundcolor, rgba(219, 230, 248, 1)));
  z-index: 5;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level5 {
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem));
  margin-left: calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level5 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width2, 200) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-6) ul.msw-nav-level5 {
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem));
  margin-left: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-6) ul.msw-nav-level5 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level5 > li.msw-nav-title {
  color: var(--msw-navigation-desktop-level5-listtitle-color, var(--msw-navigation-desktop-levels-listtitle-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level5 > li.msw-nav-item {
  text-transform: var(--msw-navigation-desktop-level5-item-texttransform, var(--msw-navigation-desktop-levels-item-texttransform, uppercase));
  color: var(--msw-navigation-desktop-level5-item-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level5 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level5 > li.msw-nav-item.msw-nav-item-selectedpath {
  background-color: var(--msw-navigation-desktop-level5-item-selected-backgroundcolor, var(--msw-navigation-desktop-levels-item-selected-backgroundcolor, rgba(205, 221, 247, 1)));
  color: var(--msw-navigation-desktop-level5-item-selected-color, var(--msw-navigation-desktop-levels-item-selected-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1))));
}
/* #endregion */
/* #region NAVIGATION - DESKTOP - LEVEL 6 Specific */
 .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level6.msw-nav-level-opened {
  display: none;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level6.msw-nav-level-opened {
  display: block;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level6 {
  background-color: var(--msw-navigation-desktop-level6-list-backgroundcolor, var(--msw-navigation-desktop-levels-list-backgroundcolor, rgba(205, 221, 247, 1)));
  z-index: 6;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level6 {
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem));
  margin-left: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"].msw-nav-leveldisplayed-6 ul.msw-nav-level6 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-6) ul.msw-nav-level6 {
  width:calc(100% - calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem));
  margin-left: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-left 1s ease, width 1s ease;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="desktop"]:not(.msw-nav-leveldisplayed-6) ul.msw-nav-level6 {
  margin-left: 0;
  margin-right: calc((var(--msw-navigation-desktop-levels-width1, 400) / 16) * 1rem);
  transition: margin-right 1s ease, width 1s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level6 > li.msw-nav-title {
  color: var(--msw-navigation-desktop-level6-listtitle-color, var(--msw-navigation-desktop-levels-listtitle-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level6 > li.msw-nav-item {
  text-transform: var(--msw-navigation-desktop-level6-item-texttransform, var(--msw-navigation-desktop-levels-item-texttransform, uppercase));
  color: var(--msw-navigation-desktop-level6-item-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level6 > li.msw-nav-item.msw-nav-item-selected,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level6 > li.msw-nav-item.msw-nav-item-selectedpath {
  background-color: var(--msw-navigation-desktop-level6-item-selected-backgroundcolor, var(--msw-navigation-desktop-levels-item-selected-backgroundcolor, rgba(195, 214, 246, 1)));
  color: var(--msw-navigation-desktop-level6-item-selected-color, var(--msw-navigation-desktop-levels-item-selected-color, var(--msw-navigation-desktop-levels-item-color, rgba(82, 92, 163, 1))));
}
/* #endregion */

/* #region NAVIGATION - MOBILE */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] {
  width: 100%;
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* height: calc(100vh - calc(((var(--msw-navigation-header-nav-mobile-height, var(--msw-header-com-mobile-height, 64)) - var(--msw-navigation-footer-nav-mobile-height, var(--msw-header-com-navcontainer-footer-mobile-height, var(--msw-header-com-mobile-height, 64)))) / 16) * 1rem)); */
  --msw-temp-navigation-footer-nav-mobile-height: var(--msw-navigation-footer-nav-mobile-height, var(--msw-header-com-navcontainer-footer-mobile-height, var(--msw-header-com-mobile-height, 64)));
  height: calc(100vh - calc(((var(--msw-navigation-header-nav-mobile-height, var(--msw-header-com-mobile-height, 64)) - var(--msw-temp-navigation-footer-nav-mobile-height)) / 16) * 1rem));
  transition: all 0.5s ease;
  position: relative;
  overflow-y: hidden;
}
.msw-header-com[data-msw-window-scroll='scrolled'] nav.msw-nav[data-msw-nav-type="mobile"] {
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* height: calc(100vh - calc(((var(--msw-navigation-header-nav-mobile-height-scroll, var(--msw-header-com-mobile-height-scroll, var(--msw-header-com-mobile-height, 64))) - var(--msw-navigation-footer-nav-mobile-height-scroll, var(--msw-header-com-navcontainer-footer-mobile-height-windowscrolled, var(--msw-header-com-mobile-height, 64)))) / 16) * 1rem)); */
  --msw-temp-navigation-header-nav-mobile-height-scroll: var(--msw-navigation-header-nav-mobile-height-scroll, var(--msw-header-com-mobile-height-scroll, var(--msw-header-com-mobile-height, 64)));
  --msw-temp-navigation-footer-nav-mobile-height-scroll: var(--msw-navigation-footer-nav-mobile-height-scroll, var(--msw-header-com-navcontainer-footer-mobile-height-windowscrolled, var(--msw-header-com-mobile-height, 64)));
  height: calc(100vh - calc(((var(--msw-temp-navigation-header-nav-mobile-height-scroll) - var(--msw-temp-navigation-footer-nav-mobile-height-scroll)) / 16) * 1rem));
  transition: all 0.5s ease;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level-opened {
  display: block;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level-closed {
  display: none;
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 1 */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level1 {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  z-index: 1;
  background-color: var(--msw-navigation-mobile-level1-list-backgroundcolor, rgba(254, 254, 254, 1));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-2 ul.msw-nav-level1 {
  overflow-y: hidden;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level1 > li.msw-nav-item {
  font-family: var(--msw-navigation-mobile-level1-item-fontfamily, "sanofi-institutional-nav", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-navigation-mobile-level1-item-fontsize, 14) / 16) * 1rem);
  font-weight: var(--msw-navigation-mobile-level1-item-fontweight, bold);
  color: var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1));
  background-color: var(--msw-navigation-mobile-level1-item-backgroundcolor, transparent);
  letter-spacing: calc((0.5 / 16) * 1rem);
  text-transform: var(--msw-navigation-mobile-level1-item-texttransform, uppercase);
  list-style: none;
  line-height: calc((var(--msw-navigation-mobile-level1-item-lineheight, 14) / 16) * 1rem);
  padding: var(--msw-navigation-mobile-level1-item-padding, 0 calc((10 / 16) * 1rem));
  margin: var(--msw-navigation-mobile-level1-item-margin, 0);
  display: flex;
  border-bottom: var(--msw-navigation-mobile-level1-item-borderbottom, solid 1px rgba(244, 247, 252, 1));
  position: static;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level1 > li.msw-nav-item:last-of-type {
  border-bottom: none 0;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link {
  display: flex;
  align-items: center;
  min-height: calc((var(--msw-navigation-mobile-level1-itemlink-minheight, var(--msw-navigation-mobile-level1-itemlink-height, 60)) / 16) * 1rem);
  height: calc((var(--msw-navigation-mobile-level1-itemlink-height, 60) / 16) * 1rem);
  color: currentColor;
  text-align: left;
  padding: var(--msw-navigation-mobile-level1-itemlink-padding, 0 calc((5 / 16) * 1rem));
  width: 100%;
  position: relative;
  pointer-events: none;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link {
	text-align: right;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level1 > li.msw-nav-item > a.msw-nav-item-link > span {
  flex: 1 1 auto;
  pointer-events: all;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level1 > li.msw-nav-item.msw-nav-item-selected > a.msw-nav-item-link {
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 1 Divider */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] > ul.msw-nav-level1 > li.msw-nav-item-divider ~ li {
  background-color: var(--msw-navigation-mobile-level1-divider-backgroundcolor, rgba(230, 237, 249, 0.5));
  border-bottom-color: var(--msw-navigation-mobile-level1-divider-borderbottom-color, rgba(230, 237, 249, 1));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level1 > li.msw-nav-item-divider {
  display: none ;
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 2+ */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) {
  background-color: var(--msw-navigation-mobile-levels-list-backgroundcolor, var(--msw-navigation-mobile-level1-list-backgroundcolor, rgba(254, 254, 254, 1)));
  position: absolute;
  overflow-y: hidden;
  height: 100%;
  z-index: 1;
  top: 0;
  margin: 0;
  padding: 0;
  left: calc((var(--msw-navigation-mobile-levels-list-indent, 10) / 16) * 1rem);
  right: 0;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) {
  left: 0;
  right: calc((var(--msw-navigation-mobile-levels-list-indent, 10) / 16) * 1rem);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-title {
  font-family: var(--msw-navigation-mobile-levels-listtitle-fontfamily, "sanofi-institutional-nav-title", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-navigation-mobile-levels-listtitle-fontsize, 14) / 16) * 1rem);
  font-weight: var(--msw-navigation-mobile-levels-listtitle-fontweight, bold);
  color: var(--msw-navigation-mobile-levels-listtitle-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1)));
  letter-spacing: calc((0.5 / 16) * 1rem);
  list-style: none;
  line-height: calc((var(--msw-navigation-mobile-levels-listtitle-lineheight, 14) / 16) * 1rem);
  text-transform: var(--msw-navigation-mobile-levels-listtitle-texttransform, uppercase);
  padding: var(--msw-navigation-mobile-levels-listtitle-padding, 0 calc((10 / 16) * 1rem) 0 calc((10 / 16) * 1rem));
  border-bottom: var(--msw-navigation-mobile-levels-listtitle-borderbottom, var(--msw-navigation-mobile-level1-item-borderbottom, solid 1px rgba(244, 247, 252, 1)));
  pointer-events: none;
  display: flex;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-title > span {
  display: flex;
  align-items: center;
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* min-height: calc((var(--msw-navigation-mobile-levels-listtitle-minheight, var(--msw-navigation-mobile-levels-listtitle-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60)))) / 16) * 1rem); */
  --msw-temp-navigation-mobile-levels-listtitle-minheight: var(--msw-navigation-mobile-levels-listtitle-minheight, var(--msw-navigation-mobile-levels-listtitle-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60))));
  min-height: calc((var(--msw-temp-navigation-mobile-levels-listtitle-minheight) / 16) * 1rem);
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* height: calc((var(--msw-navigation-mobile-levels-listtitle-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60))) / 16) * 1rem); */
  --msw-temp-navigation-mobile-levels-listtitle-height: var(--msw-navigation-mobile-levels-listtitle-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60)));
  height: calc((var(--msw-temp-navigation-mobile-levels-listtitle-height) / 16) * 1rem);
  padding: var(--msw-navigation-mobile-levels-listtitle-text-padding, 0 calc((5 / 16) * 1rem) 0 calc((5 / 16) * 1rem));
  cursor: pointer;
  pointer-events: none;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-title > button.msw-nav-title-levelup {
  color: var(--msw-navigation-mobile-levels-arrowup-color, var(--msw-navigation-arrow-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-title > button.msw-nav-title-levelup > svg.msw-nav-title-levelup-icon {
  width: calc((var(--msw-navigation-mobile-levels-arrowupicon-width, 22) / 16) * 1rem);
  height: calc((var(--msw-navigation-mobile-levels-arrowupicon-height, 22) / 16) * 1rem);
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* margin: calc((((var(--msw-navigation-mobile-levels-linkup-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60))) - var(--msw-navigation-mobile-levels-arrowupicon-height, 22)) / 2) / 16) * 1rem) calc((((var(--msw-navigation-mobile-levels-linkup-width, 30) - var(--msw-navigation-mobile-levels-arrowupicon-width, 22)) / 2) / 16) * 1rem); */
  --msw-temp-navigation-mobile-levels-linkup-height: var(--msw-navigation-mobile-levels-linkup-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60)));
  margin: calc((((var(--msw-temp-navigation-mobile-levels-linkup-height) - var(--msw-navigation-mobile-levels-arrowupicon-height, 22)) / 2) / 16) * 1rem) calc((((var(--msw-navigation-mobile-levels-linkup-width, 30) - var(--msw-navigation-mobile-levels-arrowupicon-width, 22)) / 2) / 16) * 1rem);
  fill: currentColor;
  stroke: currentColor;
  transform: rotate(180deg);
  pointer-events: all;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-title > button.msw-nav-title-levelup > svg.msw-nav-title-levelup-icon {
  transform: rotate(0deg);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-item {
  font-family: var(--msw-navigation-mobile-levels-item-fontfamily, "sanofi-institutional-nav", "sanofi-institutional-text", "Noto Sans Regular", sans-serif);
  font-size: calc((var(--msw-navigation-mobile-levels-item-fontsize, 14) / 16) * 1rem);
  font-weight: var(--msw-navigation-mobile-levels-item-fontweight, bold);
  color: var(--msw-navigation-mobile-levels-item-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1)));
  letter-spacing: calc((0.5 / 16) * 1rem);
  text-transform: var(--msw-navigation-mobile-levels-item-texttransform, none);
  list-style: none;
  line-height: calc((var(--msw-navigation-mobile-levels-item-lineheight, 14) / 16) * 1rem);
  padding: var(--msw-navigation-mobile-levels-item-padding, 0 calc((10 / 16) * 1rem));
  margin: var(--msw-navigation-mobile-levels-item-margin, 0);
  display: flex;
  border-bottom: var(--msw-navigation-mobile-levels-item-borderbottom, var(--msw-navigation-mobile-levels-listtitle-borderbottom, var(--msw-navigation-mobile-level1-item-borderbottom, solid 1px rgba(244, 247, 252, 1))));
  position: static;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-item:last-of-type {
  border-bottom: none 0;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-item > a.msw-nav-item-link {
  display: flex;
  align-items: center;
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* min-height: calc((var(--msw-navigation-mobile-levels-itemlink-minheight, var(--msw-navigation-mobile-levels-itemlink-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60)))) / 16) * 1rem); */
  --msw-temp-navigation-mobile-levels-itemlink-minheight: var(--msw-navigation-mobile-levels-itemlink-minheight, var(--msw-navigation-mobile-levels-itemlink-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60))));
  min-height: calc((var(--msw-temp-navigation-mobile-levels-itemlink-minheight) / 16) * 1rem);
  /* use temp css custom properties as workaround to cssnano bug: https://github.com/cssnano/cssnano/issues/880#issue-568564657 */
  /* height: calc((var(--msw-navigation-mobile-levels-itemlink-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60))) / 16) * 1rem); */
  --msw-temp-navigation-mobile-levels-itemlink-height: var(--msw-navigation-mobile-levels-itemlink-height, var(--msw-navigation-mobile-levels-height, var(--msw-navigation-mobile-level1-itemlink-height, 60)));
  height: calc((var(--msw-temp-navigation-mobile-levels-itemlink-height) / 16) * 1rem);
  color: currentColor;
  text-align: left;
  padding: var(--msw-navigation-mobile-levels-itemlink-padding, 0 calc((5 / 16) * 1rem));
  width: 100%;
  position: relative;
  pointer-events: none;
}
[dir="rtl"] .msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-item > a.msw-nav-item-link {
	text-align: right;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-item > a.msw-nav-item-link > span {
  flex: 1 1 auto;
  pointer-events: all;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul:not(.msw-nav-level1) > li.msw-nav-item.msw-nav-item-selected > a {
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 1 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-2 ul.msw-nav-level1 > li.msw-nav-title,
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-2 ul.msw-nav-level1 > li.msw-nav-item {
  border-bottom-color: transparent;
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 2 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level2 {
  background-color: var(--msw-navigation-mobile-level2-list-backgroundcolor, var(--msw-navigation-mobile-levels-list-backgroundcolor, var(--msw-navigation-mobile-level1-list-backgroundcolor, rgba(244, 247, 252, 1))));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level2 > li.msw-nav-title {
  background-color: var(--msw-navigation-mobile-level2-listtitle-backgroundcolor, var(--msw-navigation-mobile-levels-listtitle-backgroundcolor, transparent));
  color: var(--msw-navigation-mobile-level2-listtitle-color, var(--msw-navigation-mobile-levels-listtitle-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level2-listtitle-borderbottom-color, var(--msw-navigation-mobile-levels-listtitle-borderbottom-color, rgba(229, 237, 250, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level2 > li.msw-nav-item {
  color: var(--msw-navigation-mobile-level2-item-color, var(--msw-navigation-mobile-levels-item-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level2-item-borderbottom-color, var(--msw-navigation-mobile-levels-item-borderbottom-color, rgba(229, 237, 250, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-2 ul.msw-nav-level2 {
  overflow-y: auto;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-3 ul.msw-nav-level2 {
  overflow-y: hidden;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-3 ul.msw-nav-level2 > li.msw-nav-title,
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-3 ul.msw-nav-level2 > li.msw-nav-item {
  background-color: transparent;
  border-bottom-color: transparent;
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 3 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level3 {
  background-color: var(--msw-navigation-mobile-level3-list-backgroundcolor, var(--msw-navigation-mobile-levels-list-backgroundcolor, var(--msw-navigation-mobile-level1-list-backgroundcolor, rgba(229, 237, 250, 1))));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level3 > li.msw-nav-title {
  background-color: var(--msw-navigation-mobile-level3-listtitle-backgroundcolor, var(--msw-navigation-mobile-levels-listtitle-backgroundcolor, transparent));
  color: var(--msw-navigation-mobile-level3-listtitle-color, var(--msw-navigation-mobile-levels-listtitle-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level3-listtitle-borderbottom-color, var(--msw-navigation-mobile-levels-listtitle-borderbottom-color, rgba(219, 230, 248, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level3 > li.msw-nav-item {
  color: var(--msw-navigation-mobile-level3-item-color, var(--msw-navigation-mobile-levels-item-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level3-item-borderbottom-color, var(--msw-navigation-mobile-levels-item-borderbottom-color, rgba(219, 230, 248, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-3 ul.msw-nav-level3 {
  overflow-y: auto;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-4 ul.msw-nav-level3 {
  overflow-y: hidden;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-4 ul.msw-nav-level3 > li.msw-nav-title,
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-4 ul.msw-nav-level3 > li.msw-nav-item {
  background-color: transparent;
  border-bottom-color: transparent;
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 4 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level4 {
  background-color: var(--msw-navigation-mobile-level4-list-backgroundcolor, var(--msw-navigation-mobile-levels-list-backgroundcolor, var(--msw-navigation-mobile-level1-list-backgroundcolor, rgba(219, 230, 248, 1))));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level4 > li.msw-nav-title {
  background-color: var(--msw-navigation-mobile-level4-listtitle-backgroundcolor, var(--msw-navigation-mobile-levels-listtitle-backgroundcolor, transparent));
  color: var(--msw-navigation-mobile-level4-listtitle-color, var(--msw-navigation-mobile-levels-listtitle-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level4-listtitle-borderbottom-color, var(--msw-navigation-mobile-levels-listtitle-borderbottom-color, rgba(205, 221, 247, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level4 > li.msw-nav-item {
  color: var(--msw-navigation-mobile-level4-item-color, var(--msw-navigation-mobile-levels-item-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level4-item-borderbottom-color, var(--msw-navigation-mobile-levels-item-borderbottom-color, rgba(205, 221, 247, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-4 ul.msw-nav-level4 {
  overflow-y: auto;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-5 ul.msw-nav-level4 {
  overflow-y: hidden;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-5 ul.msw-nav-level4 > li.msw-nav-title,
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-5 ul.msw-nav-level4 > li.msw-nav-item {
  background-color: transparent;
  border-bottom-color: transparent;
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 5 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level5 {
  background-color: var(--msw-navigation-mobile-level5-list-backgroundcolor, var(--msw-navigation-mobile-levels-list-backgroundcolor, var(--msw-navigation-mobile-level1-list-backgroundcolor, rgba(205, 221, 247, 1))));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level5 > li.msw-nav-title {
  background-color: var(--msw-navigation-mobile-level5-listtitle-backgroundcolor, var(--msw-navigation-mobile-levels-listtitle-backgroundcolor, transparent));
  color: var(--msw-navigation-mobile-level5-listtitle-color, var(--msw-navigation-mobile-levels-listtitle-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level5-listtitle-borderbottom-color, var(--msw-navigation-mobile-levels-listtitle-borderbottom-color, rgba(189, 207, 241, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level5 > li.msw-nav-item {
  color: var(--msw-navigation-mobile-level5-item-color, var(--msw-navigation-mobile-levels-item-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level5-item-borderbottom-color, var(--msw-navigation-mobile-levels-item-borderbottom-color, rgba(189, 207, 241, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-5 ul.msw-nav-level5 {
  overflow-y: auto;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-6 ul.msw-nav-level5 {
  overflow-y: hidden;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-6 ul.msw-nav-level5 > li.msw-nav-title,
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-6 ul.msw-nav-level5 > li.msw-nav-item {
  background-color: transparent;
  border-bottom-color: transparent;
}
/* #endregion */
/* #region NAVIGATION - MOBILE - LEVEL 6 Specific */
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level6 {
  background-color: var(--msw-navigation-mobile-level6-list-backgroundcolor, var(--msw-navigation-mobile-levels-list-backgroundcolor, var(--msw-navigation-mobile-level1-list-backgroundcolor, rgba(189, 207, 241, 1))));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level6 > li.msw-nav-title {
  background-color: var(--msw-navigation-mobile-level6-listtitle-backgroundcolor, var(--msw-navigation-mobile-levels-listtitle-backgroundcolor, transparent));
  color: var(--msw-navigation-mobile-level6-listtitle-color, var(--msw-navigation-mobile-levels-listtitle-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level6-listtitle-borderbottom-color, var(--msw-navigation-mobile-levels-listtitle-borderbottom-color, rgba(169, 191, 235, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul.msw-nav-level6 > li.msw-nav-item {
  color: var(--msw-navigation-mobile-level6-item-color, var(--msw-navigation-mobile-levels-item-color, var(--msw-navigation-mobile-level1-item-color, rgba(82, 92, 163, 1))));
  border-bottom-color: var(--msw-navigation-mobile-level6-item-borderbottom-color, var(--msw-navigation-mobile-levels-item-borderbottom-color, rgba(169, 191, 235, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"].msw-nav-leveldisplayed-6 ul.msw-nav-level6 {
  overflow-y: auto;
}
/* #endregion */

/* #region NAVIGATION - KEYBOARD & ACCESSIBILITY HELPERS */
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] > ul > li:focus,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] > ul > li a:focus {
  outline: var(--msw-navigation-desktop-level1-item-focus-outline, 1px dotted rgba(187, 186, 198, 1));
  outline-offset: calc((var(--msw-navigation-desktop-level1-item-focus-outlineoffset, 3) / 16) * 1rem);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] > ul > li ul li:focus,
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] > ul > li ul li a:focus {
  outline: var(--msw-navigation-desktop-levels-item-focus-outline, 1px dotted rgba(187, 186, 198, 1));
  outline-offset: calc((var(--msw-navigation-desktop-levels-item-focus-outlineoffset, -3) / 16) * 1rem);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul > li a:focus {
  outline: var(--msw-navigation-mobile-levels-item-focus-outline, 1px dotted rgba(187, 186, 198, 1));
  outline-offset: calc((var(--msw-navigation-mobile-levels-item-focus-outlineoffset, -3) / 16) * 1rem);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] button.msw-nav-open-child:focus {
  outline: var(--msw-navigation-desktop-arrow-focus-outline, 1px dotted rgba(187, 186, 198, 1));
  outline-offset: calc((var(--msw-navigation-desktop-arrow-focus-outlineoffset, -3) / 16) * 1rem);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] button.msw-nav-open-child:focus,
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] button.msw-nav-title-levelup:focus {
  outline: var(--msw-navigation-mobile-arrow-focus-outline, 1px dotted rgba(187, 186, 198, 1));
  outline-offset: calc((var(--msw-navigation-mobile-arrow-focus-outlineoffset, -3) / 16) * 1rem);
}
.msw-header-com nav.msw-nav .msw-nav-tab-only {
  position: absolute;
  top: -10000px;
  left: -10000px;
}
.msw-header-com nav.msw-nav .msw-nav-tab-only:focus {
  position: static;
}
.msw-header-com nav.msw-nav ul > li.msw-nav-skip-item {
  --msw-navigation-skipitem-padding: calc((10 / 16) * 1rem);
  padding: var(--msw-navigation-skipitem-padding, calc((10 / 16) * 1rem));
  position: absolute;
  top: -10000px;
  left: -10000px;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul.msw-nav-level2 > li.msw-nav-skip-item {
  margin-left: calc((100% - calc((var(--msw-navigation-desktop-maxwidth, 1200) / 16) * 1rem)) / 2);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] ul > li.msw-nav-skip-item > button {
  background-color: var(--msw-navigation-desktop-skipbutton-backgroundcolor, rgba(235, 235, 235, 1));
  color: var(--msw-navigation-desktop-skipbutton-color, var(--msw-navigation-skipbutton-color, rgba(82, 92, 163, 1)));
  border: var(--msw-navigation-desktop-skipbutton-border, var(--msw-navigation-skipbutton-border, 1px dotted rgba(82, 92, 163, 1)));
  border-radius: calc((var(--msw-navigation-desktop-skipbutton-borderradius, 15) / 16) * 1rem);
  padding: var(--msw-navigation-desktop-skipbutton-padding, calc((5 / 16) * 1rem) calc((10 / 16) * 1rem) calc((5 / 16) * 1rem) calc((10 / 16) * 1rem));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] ul > li.msw-nav-skip-item > button {
  background-color: var(--msw-navigation-mobile-skipbutton-backgroundcolor, rgba(235, 235, 235, 1));
  color: var(--msw-navigation-mobile-skipbutton-color, var(--msw-navigation-skipbutton-color, rgba(82, 92, 163, 1)));
  border: var(--msw-navigation-mobile-skipbutton-border, var(--msw-navigation-skipbutton-border, 1px dotted rgba(82, 92, 163, 1)));
  border-radius: calc((var(--msw-navigation-mobile-skipbutton-borderradius, 15) / 16) * 1rem);
  padding: var(--msw-navigation-mobile-skipbutton-padding, calc((5 / 16) * 1rem) calc((10 / 16) * 1rem) calc((5 / 16) * 1rem) calc((10 / 16) * 1rem));
}
.msw-header-com nav.msw-nav ul > li.msw-nav-skip-item > button:focus  {
  /* reset */
  outline: none 0 transparent;
}
/* #endregion */
/* #region NAVIGATION - ARROW */
.msw-header-com nav.msw-nav .msw-nav-item-childrenlink button {
  min-width: calc(((var(--msw-navigation-arrow-minwidth, 30)) / 16) * 1rem);
  position: relative;
  flex: 0 1 auto;
  color: var(--msw-navigation-arrow-color, rgba(82, 92, 163, 1));
  height: 100%;
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] .msw-nav-item-childrenlink button {
  min-width: calc(((var(--msw-navigation-desktop-arrow-minwidth, 30)) / 16) * 1rem);
  color: var(--msw-navigation-desktop-arrow-color, var(--msw-navigation-arrow-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] .msw-nav-item-childrenlink button {
  min-width: calc(((var(--msw-navigation-mobile-arrow-minwidth, 30)) / 16) * 1rem);
  color: var(--msw-navigation-mobile-arrow-color, var(--msw-navigation-arrow-color, rgba(82, 92, 163, 1)));
}
.msw-header-com nav.msw-nav .msw-nav-item-childrenlink > button.msw-nav-open-child > svg.msw-nav-open-child-icon {
  width: calc((var(--msw-navigation-arrowicon-width, 22) / 16) * 1rem);
  height: calc((var(--msw-navigation-arrowicon-width, 22) / 16) * 1rem);
  margin: 0 calc((((var(--msw-navigation-arrow-minwidth, 30) - var(--msw-navigation-arrowicon-width, 22)) / 2) / 16) * 1rem);
  fill: currentColor;
  stroke: currentColor;
  transform: rotate(0deg);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="desktop"] .msw-nav-item-childrenlink > button.msw-nav-open-child > svg.msw-nav-open-child-icon {
  width: calc((var(--msw-navigation-desktop-arrowicon-width, 22) / 16) * 1rem);
  height: calc((var(--msw-navigation-desktop-arrowicon-width, 22) / 16) * 1rem);
  margin: 0 calc((((var(--msw-navigation-desktop-arrow-minwidth, 30) - var(--msw-navigation-desktop-arrowicon-width, 22)) / 2) / 16) * 1rem);
}
.msw-header-com nav.msw-nav[data-msw-nav-type="mobile"] .msw-nav-item-childrenlink > button.msw-nav-open-child > svg.msw-nav-open-child-icon {
  width: calc((var(--msw-navigation-mobile-arrowicon-width, 22) / 16) * 1rem);
  height: calc((var(--msw-navigation-mobile-arrowicon-width, 22) / 16) * 1rem);
  margin: 0 calc((((var(--msw-navigation-mobile-arrow-minwidth, 30) - var(--msw-navigation-mobile-arrowicon-width, 22)) / 2) / 16) * 1rem);
}
[dir="rtl"] .msw-header-com nav.msw-nav .msw-nav-item-childrenlink > button.msw-nav-open-child > svg.msw-nav-open-child-icon {
  transform: rotate(180deg);
}
/* #endregion */

/* #region EDIT MODE */
:root {
  --msw-header-com-wrapper-editmode-background-color: rgba(82, 92, 163, 1);
}
.msw-header-com-wrapper--editmode {
  background-color: var(--msw-header-com-wrapper-editmode-background-color, rgba(82, 92, 163, 1));
}
/* #endregion */