.pin {
    &:before {
        content: "";
        z-index: 1;
        position: absolute;
        border-radius: 50%;
        bottom: 7.5px;
        border: 9px solid #ea4435;
        width: 18px;
        height: 15px;
    }

    &:after {
        content: "";
        position: absolute;
        width: 0px;
        height: 0px;
        bottom: -11px;
        border: 9px solid transparent;
        border-top: 16px solid #ea4435;
    }
}

.index {
    top: 1px;

    &.double {
        left: 2px;
    }

    &.single {
        left: 6px;
    }
}
