nav {
    .icon__image {
        min-width: 24px;
    }
}

.nav__logo {
    transition: transform 0.25s ease-out;
}

.nav__mobile {
    .nav__logo {
        max-width: 165px;
    }
}

.nav__right,
.nav__inner {
    transition: transform 0.25s ease-out;
}

.nav__item {
    &:hover,
    &:focus,
    &:focus-within {
        @media screen and (min-width: $width-desktop-min + 1) {
            .subNav {
                display: block;
            }
        }
    }

    &--active {
        @media screen and (min-width: $width-desktop-min + 1) {
            .subNav {
                display: block;
            }
        }
    }

    .subNav {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    }

    .subNav__container {
        ul {
            @media screen and (min-width: $width-desktop-min + 1) {
                max-width: 325px;
            }

            @media screen and (min-width: 1280px) {
                max-width: 400px;
            }
        }
    }

    .subNav__item {
        max-width: 100%;

        .subNav__title,
        .subNav__itemNextButton {
            transition: all 0.25s ease-in-out;
        }

        .subNav__container {
            margin-left: 0;
        }

        @media screen and (min-width: $width-desktop-min + 1) {
            max-width: 400px;
            &:hover,
            &:focus {
                background: rgba(var(--theme-invert-bg, white), 0.15);
            }
        }
    }

    $var: 5;
    @for $i from 1 through 5 {
        @media screen and (min-width: $width-desktop-min + 1) {
            .subNav__item {
                > .subNav--#{$i} {
                    top: 0;
                    display: none;
                    opacity: 0;
                    height: 100%;
                }

                &[dir="ltr"] {
                    > .subNav--#{$i} {
                        left: 325px;
                        transition: left 1s;

                        &.subNav__fourthLevel {
                            left: 230px;
                        }

                        &.subNav__fifthLevel {
                            left: 130px;
                        }
                    }
                }

                &[dir="rtl"] {
                    > .subNav--#{$i} {
                        right: 325px;
                        transition: right 1s;

                        &.subNav__fourthLevel {
                            right: 230px;
                        }

                        &.subNav__fifthLevel {
                            right: 130px;
                        }
                    }

                    .subNav__itemNextButton {
                        transform: rotate(180deg);
                    }
                }
            }

            .subNav__item:hover,
            .subNav__item:focus-within {
                > .subNav--#{$i} {
                    display: block;
                    opacity: 1;
                    z-index: 50;
                }
            }

            .subNav__item:hover {
                > .subNav__itemLink,
                > .subNav__itemNextButton,
                .subNav__container {
                    background: rgba(var(--theme-color-primary2-rgb), 0.15);
                }
            }
        }

        @media screen and (min-width: 1280px) {
            .subNav__item {
                &[dir="ltr"] > .subNav--#{$i} {
                    left: 400px;
                }

                &[dir="rtl"] > .subNav--#{$i} {
                    right: 400px;
                }
            }
        }
    }
}

.nav__itemSkipButton {
    border-radius: 15px;
    background: rgba($color-black, 0.05);
    color: var(--theme-invert-bg, black);
    transform: translateX(-5px);

    &:focus {
        padding: 5px 10px;
    }
}

/* Mobile */
.nav__mobile__menu {
    background: var(--theme-invert-bg, white);
    height: 0;
    opacity: 0;

    .nav__mobile__menuItems {
        opacity: 0;
    }

    .nav__mobile__navList {
        height: calc(100% - 100px);
    }

    &--open {
        height: 100%;
        opacity: 1;

        .nav__mobile__menuItems {
            opacity: 1;
        }
    }

    .subNav__list {
        height: calc(100% - 145px);

        @media screen and (max-height: 570px) {
            height: calc(100% - 180px);
        }
    }

    &[dir="rtl"] {
        .subNav__itemNextButton {
            transform: rotate(180deg);
        }

        .subNav__back {
            display: flex;
            flex-direction: row-reverse;

            > .icon {
                left: 0.5rem;
                top: 0.6rem;
                transform: rotate(45deg);
            }
        }
    }
}

.subNav--mobile {
    top: 0;
    display: none;

    &.subNav--open {
        display: block;
    }
}

.subNav--1 {
    top: -60px;
}

@media screen and (min-width: $width-desktop-min + 1) {
    .subNav--1 {
        top: 0;
    }
}

.nav__mobile__menu__main {
    color: var(--theme-invert-bg, black);

    ul {
        display: flex;
        flex-direction: column;
    }

    &--open {
        right: 100%;
    }
}

/* Body Style */
.push-to-left {
    @media screen and (max-width: $width-tablet-max) {
        right: 100%;
    }
}

.nav-stuck {
    .nav__logoWrap--resize {
        &.nav__inner {
            padding-bottom: 0;
            transform: translateX(-10px);
        }

        .nav__logo {
            transform: scale(0.8);
        }
    }

    .nav__right {
        transform: translateX(30px);
    }
}

.nav__left {
    &.justify-end {
        > .nav__item:last-child {
            padding-right: 0 !important;
        }
    }

    &.justify-start {
        margin-left: 0 !important;
    }
}

.jump__nav_shadow {
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 30%);
}

.jump__nav_shadow--mobile {
    @media screen and (max-width: 768px) {
        box-shadow: 0 2px 3px 0 rgb(0 0 0 / 30%);
    }
}

/* Edit Mode */

.mgnl-admin {
    .subNav {
        display: none;
    }
}

.navLink a:first-child {
    padding-left: 8px;
}

.nav__desktop {
    @media screen and (max-width: 1280px) {
        .subNav__title {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .subNav__itemWrap {
            .subNav__itemLink {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }

    @media screen and (min-height: 576px) and (min-width: 1025px) {
        .subNav__container {
            padding-bottom: 20px;
        }

        .subNav__list {
            max-height: 400px;
        }

        .subNav,
        .subNav__bg {
            max-height: 435px;
        }
    }

    @media screen and (min-height: 768px) and (min-width: 1025px) {
        .subNav__list {
            max-height: 550px;
        }

        .subNav,
        .subNav__bg {
            max-height: 585px;
        }
    }
}

.activeAnchorLink {
    color: var(--theme-pageMenu-active-anchor-link);
}
