@mixin listStyleType($type, $content: "") {
    list-style: $type;

    @if $content {
        &::before {
            content: $content;
            position: absolute;
            left: 0;
            font-size: 1.5rem;
        }
    }
}