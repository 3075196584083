@import "../variables.scss";
@import "../mixins/breakpoint.scss";

.wrap {
    max-width: $width-content-wrap;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 1rem;

    @include breakpoint(md) {
        padding: 0 2rem;
    }
}

.wrap--sm-full {
    padding: 0 1rem;

    @include breakpoint(md) {
        padding: 0 2rem;
    }
}

.page--skinny main .wrap {
    max-width: $width-content-wrap-skinny;
}
