/* classes */
.hover\:bg-default:hover,
.bg-default {
    background-color: var(--theme-bg-default-color);
}
.hover\:bg-primary:hover,
.bg-primary {
    background-color: var(--theme-color-primary2);
}
.hover\:bg-invert:hover,
.bg-invert {
    background-color: var(--theme-bg-default-color-invert-color);
}
.hover\:bg-tertiary:hover,
.bg-tertiary {
    background-color: var(--theme-bg-default-color-tertiary);
}
