.img-caption {
    font-size: var(--theme-image-caption-font-size);
    font-family: var(--theme-image-caption-font-family);
}

.uiImage.overflow-auto {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--theme-button-bg-color);
        border-radius: 10px;
    }
}

.image-crisp {
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

.image-magnifier-container {
    position: relative;
}

.image-magnifier-glass {
    background-color: #FFF;
    position: absolute;
    border: 3px solid #000;
    border-radius: 50%;
    cursor: none;
    width: 250px;
    height: 250px;
    z-index: 999;
}

.image-magnifier-glass.hidden {
    display: none;
}