/* variables */
$accent-color: var(--form-accent-color, #3b82f6);

.formComponent {
    .sliderBar {
        background-color: $accent-color;
    }

    /* Slider */
    /* Chrome, Safari, and Opera */
    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: $accent-color;
        cursor: pointer;
        border-radius: 50%;
    }

    /* For Firefox */
    input[type='range']::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background-color: $accent-color;
        cursor: pointer;
        border-radius: 50%;
    }

    /* For IE */
    input[type='range']::-ms-thumb {
        width: 20px;
        height: 20px;
        background-color: $accent-color;
        cursor: pointer;
        border-radius: 50%;
    }

    /* For checkboxes */
    input[type='checkbox'] {
        appearance: none;
        width: 18px;
        height: 26px;
        border-radius: 2px;
        position: relative;
        &:checked {
            background-color: $accent-color !important;
            &::after {
                content: '';
                position: absolute;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }

    /* For radio buttons */
    input[type='radio'] {
        appearance: none;
        width: 18px;
        height: 26px;
        border-radius: 50%;
        position: relative;
        &:checked {
            background-color: $accent-color !important;
            &::after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                background-color: white;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
