@import "node_modules/tiny-slider/src/tiny-slider";

.carousel {
    .tns-item:not(.tns-slide-active) {
        .lazyload {
            display: none;

            &.loaded {
                display: block;
                height: auto;
            }
        }
    }

    :not(.tns-ah) [dir="ltr"] {
        .tns-lazy-img:not(.tns-complete) {
            display: none; // this will fix the height of carousel when autoheight is disabled and lazyload is enabled
        }
    }

    .tns-slide-active {
        z-index: 10; // Fix flickering issue on loop
    }

    .tns-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px 10px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        button {
            width: 12px;
            height: 12px;
            margin: 5px;
            border-radius: 50px;
            background-color: white;
            border-color: var(--theme-carousel-nav-color);
            border: 2px solid var(--theme-carousel-nav-color);

            &.tns-nav-active {
                background-color: var(--theme-carousel-nav-color);
                border: 2px solid white;
                width: 14px;
                height: 14px;
            }
        }
    }

    .tns-outer {
        .tns-visually-hidden {
            display: none;
        }
        width: 100%
    }

    .justify-center & .ContentGrid,
    .ContentGrid > .justify-center {
        height: 100%;
    }

    .carousel__autoplayBtn {
        border-radius: var(--theme-button-border-radius);
        height: 32px;
        width: 32px;
        position: relative;

        .carousel__btn {
            color: var(--theme-carousel-playpause-btn-icon-color);
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            background-color: var(--theme-carousel-playpause-btn-bg-hover-color);

            .carousel__btn {
                color: var(--theme-carousel-playpause-btn-icon-hover-color);
            }

            .carousel__playBtn {
                border-left-color: var(--theme-carousel-playpause-btn-icon-hover-color);
            }
        }

        .carousel__playBtn {
            text-indent: -9999px;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 12px solid var(--theme-carousel-playpause-btn-icon-color);
            height: 0;
        }
    }
}

.carousel__wrap--hasPadding {
    padding-bottom: 45px;

    &.carousel__paginationTop {
        padding-bottom: 0;
    }
}

.carousel__controls {
    z-index: 20;

    button:disabled {
        opacity: 0.5;
    }

    + .tns-nav {
        position: static;
    }    

    .carousel__innerControls {
        @media screen and (min-width: $width-medium-tablet-min) and (max-width: $width-tablet-max) {
            &.carousel__controls--prev {
                left: 1rem;
            }

            &.carousel__controls--next {
                right: 1rem;
            }
        }
    }
}

.carousel__playbtnTop {
    .carousel__autoplayBtn {
        position: absolute;
        z-index: 1;
    }
}

.carousel__paginationTop {
    .tns-nav {
        position: absolute;
        z-index: 1;
        bottom: 0;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: max-content;
    }
}
.carousel__thumbnail:hover{
    border: 2px solid var(--theme-carousel-nav-color);
}

.carousel__thumbnail--active{
    border: 2px solid var(--theme-carousel-nav-color);
}