/* Fonts */
$font-lato: "Lato", sans-serif;
$font-times-new-roman: "Times New Roman", Times, serif;
$font-avant-garde: "Avant Garde", Helvetica, Arial, sans-serif;
$font-noto-sans-light: "Noto Sans Light", Helvetica, Arial, sans-serif;
$font-noto-sans-regular: "Noto Sans Regular", Helvetica, Arial, sans-serif;

/* Colors */
/* Brand Colors */
$color-brand-main: #000;

// Blacks
$color-black: #000;

// Greys
$color-grey-26: #262626;
$color-grey-4e: #4e4e4e;
$color-grey-ef: #efeeee;

// Whites
$color-white: #fff;

// Greens
$color-green-16: #16874f;
$color-green-17: #179a59;

// Yellows
$color-yellow-ff: #ffd100;
$color-yellow-d9: #d9b512;

// Orange
$color-orange-f7: #f76740;

/* Min Sizes */
$width-tablet-min: 500px;
$width-medium-tablet-min: 768px;
$width-large-tablet-min: 900px;
$width-desktop-min: 1024px;
$width-standard-screen-min: 1366px;
$width-medium-screen-min: 1600px;
$width-large-screen-min: 1920px;

/* Max Sizes */
$width-mobile-max: $width-tablet-min - 1;
$width-small-tablet-max: $width-medium-tablet-min - 1;
$width-tablet-max: $width-desktop-min - 1;
$width-standard-screen-max: $width-standard-screen-min - 1;
$width-medium-screen-max: $width-medium-screen-min - 1;
$width-desktop-max: $width-large-screen-min - 1;
$width-content-wrap: 1400px;
$width-content-wrap-skinny: 1100px;
$wrap-padding-w: 1rem;

$width-main-content: 65%;
$width-main-sidebar: 30%;
$width-full-page: 100%;
