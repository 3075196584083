.flex-2 {
    flex: 2;
}

input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}

.modal-sectionTitle {
    &::before {
        content: "";
        position: absolute;
        top: 51%;
        left: -12px;
        transform: translate(0, -50%);
        background-color: var(--theme-color-primary2);
        border-radius: 100%;
        width: 4px;
        height: 4px;
    }
}

.modal-card {
    background-color: #ffffff;

    .modal-cardButton {
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.3s ease;
    }

    &:hover,
    &:focus {
        justify-content: space-evenly;

        .modal-cardButton {
            opacity: 1;
            height: auto;
        }
    }
}
