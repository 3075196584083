body {
    color: var(--theme-body-text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--theme-title-text-color);
}
