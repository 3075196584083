.isi-tray {
    transition: max-height 0.3s ease-in;
    overflow: hidden;

    &.isiTray__dropShadow {
        box-shadow: 0 -1px 10px 0 rgb(0 0 0 / 75%);
    }

    .isiTray__contentSectionItem {
        padding-top: 0 !important;
    }

    * .ContentSectionItem {
        padding-top: 0 !important;

        > div,
        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 0 !important;
        }

        .rich-text {
            margin-top: 0 !important;
            margin-bottom: 0 !important;

            p {
                margin-bottom: 0 !important;
            }
        }
    }

    @media (max-width: 1025px) {
        * .contentBlock__title,
        .contentBlock__subtitle {
            display: none;
        }
    }
}

.isi-information {
    @media (max-width: 1025px) {
        * .contentBlock__title,
        .contentBlock__subtitle {
            display: none;
        }
    }
}

.rich-text-isi--V2 {
    h1,
    h2,
    h3,
    h4,
    h5,
    div {
        margin-bottom: 0 !important;
    }

    p {
        margin: 5px 0;

        &:last-child {
            margin-bottom: 10px;
        }
    }

    ul {
        padding-left: 1em;
        margin: 0;

        li {
            margin: 5px 0;
        }
    }
}