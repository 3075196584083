@import "../mixins/breakpoint.scss";

.pressRelease {
    &Page {
        @include breakpoint("md") {
            > .heroContainer {
                margin-bottom: -6rem;
            }
        }
    }

    a.pdf-link,
    a.pdf-link:hover {
        color: var(--theme-color-primary1) !important;
    }

    a,
    a:hover {
        color: var(--theme-color-primary1) !important;
    }
    
}
