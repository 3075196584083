.authForm__wrap {
    min-height: 60vh;
}

.authForm {
    .authForm__container {
        background-color: var(--theme-forms-bg-color);
    }

    h1 {
        @apply text-5xl;
        color: var(--theme-forms-title-color);
    }

    h4 {
        @apply text-xl font-semibold;
        color: var(--theme-forms-subtitle-color);
    }

    h5 {
        @apply mt-4;
        color: var(--theme-forms-subtitle-color);
    }

    legend {
        color: var(--theme-forms-subtitle-color);
    }

    iframe {
        @apply m-auto;
    }

    a:not(.swrx__button) {
        color: var(--theme-link-text-color);

        &:hover {
            color: var(--theme-link-text-color-hover);
        }

        &.button {
            color: var(--theme-button-text-color);

            &:hover {
                border-color: var(--theme-button-text-color-hover);
                color: var(--theme-button-text-color-hover);
            }
        }
    }

    .authForm__header {
        @apply py-4 px-4 mb-8 md:py-10 md:px-16 md:mb-8;
        background-color: var(--theme-forms-header-color);
    }

    .capture_tip_validating {
        display: none;
    }

    p,
    form {
        .capture_checkbox label {
            display: inline;
        }
    }

    form {
        @apply px-4 md:px-16;
    }

    .password_wrap {
        input[type="text"] ~ .toggle_password .password_show {
            display: none;
        }

        input[type="password"] ~ .toggle_password .password_hide {
            display: none;
        }
    }

    select,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="date"] {
        @apply px-4 py-2 w-full rounded border border-gray-400 placeholder-gray-400 text-black;
        scroll-margin-top: 10em;
        color: var(--theme-forms-text-color);
    }

    input[type="checkbox"] {
        @apply mt-8 mr-4 transform scale-150;
        scroll-margin-top: 10em;

        &:focus-visible {
            outline: 2px solid $color-black;
        }
    }

    .capture_btn,
    input[type="submit"] {
        @apply float-right w-auto font-title overflow-hidden inline-block px-5 rounded-full border border-solid cursor-pointer py-3 lg:py-3 font-semibold;
    }

    .changePwBtn {
        @apply px-5 py-2 mt-4 lg:py-1 overflow-hidden inline-block rounded-full border border-solid text-center w-auto;
    }

    .authForm__errorMsg,
    p.error-message,
    .capture_form_error,
    .capture_tip_error {
        @apply text-red-600 pt-4 px-0;
    }

    .authForm__successMsg {
        @apply text-green-600 px-0;
    }

    .capture_tip_error {
        min-height: 3rem;
    }

    .capture_footer {
        @apply mt-2;
    }

    label {
        @apply mb-2 flex;
        color: var(--theme-forms-label-color);

        a {
            @apply underline;
            color: var(--theme-link-text-color);

            &:hover {
                color: var(--theme-link-text-color-hover);
            }
        }

        p {
            @apply px-0 inline;
        }
    }

    .docCheck__inputWrap {
        min-height: 7rem;
    }

    // API Forms
    .authForm__item {
        select,
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="date"] {
            @apply mt-0;
        }

        input[type="checkbox"] {
            @apply mt-2 mb-2;
        }

        .authForm__checkboxLabel {
            @apply mb-4;
            color: var(--theme-forms-text-color);
        }

        p.error-message {
            @apply pt-0;
        }

        .authForm__errWrap {
            min-height: 3rem;
        }
    }
}

.terms-modal {
    left: 50%;
    transform: translate(-50%, -50%);
}

input:read-only,
input:disabled,
select:disabled {
    background-color: #e2e2e2 !important;
    outline: none;
}

.form-text {
    @apply mb-4;
    padding-left: 28px;

    p {
        @apply px-0;
        @apply mt-1;
    }

    ul,
    ol {
        list-style: inherit;
        list-style-position: outside;
        padding-left: 20px;

        li {
            @apply mb-1;

            li {
                @apply mt-1;
            }
        }
    }
}
