.chevron {
    border-style: solid;
    border-width: 0.2rem 0.2rem 0 0;
    content: "";
    display: inline-block;
    height: 0.55rem;
    left: 0.15rem;
    position: relative;
    top: 0.15rem;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.55rem;
}

.chevron--right {
    left: 0;
    transform: rotate(45deg);
}

.chevron--down {
    top: 0;
    transform: rotate(135deg);
}

.chevron--left {
    left: 0.25rem;
    top: 0.5rem;
    transform: rotate(-135deg);
}

.chevron--up {
    left: 0;
    top: 0.2rem;
    height: 0.7rem;
    width: 0.7rem;
}
