// For use with light-modules/sanofi-lm-platform/themes/sanofi-platform/src/js/modules/isBrowserSupported.js

// Only show in supported browsers
.has-browser-support {
    display: none;
}

// Only show in NOT supported browsers
.not-browser-support {
    display: block;
}

html.has-js.browser-supported {
    .has-browser-support {
        display: inherit;
    }

    .not-browser-support {
        display: none;
    }
}
