.button {
    display: inline-block;
    cursor: pointer;
    text-align: center;

    &[disabled] {
        opacity: 0.6;
        cursor: default;
    }
}

.button--full {
    display: block;
}

.button--default {
    background-color: var(--theme-button-bg-color);
    border-color: var(--theme-button-bg-color);
    border-radius: var(--theme-button-border-radius);
    color: var(--theme-button-text-color);
    padding: var(--theme-button-padding);

    &.active,
    &:not(:focus):hover {
        background-color: var(--theme-button-bg-color-hover);
        border-color: var(--theme-button-text-color-hover);
        color: var(--theme-button-text-color-hover);
    }
}

.button--secondary {
    background-color: var(--theme-button-secondary-bg-color);
    border-color: var(--theme-button-secondary-bg-color);
    border-radius: var(--theme-button-secondary-border-radius);
    color: var(--theme-button-secondary-text-color);
    padding: var(--theme-button-secondary-padding);

    &.active,
    &:not(:focus):hover {
        background-color: var(--theme-button-secondary-bg-color-hover);
        border-color: var(--theme-button-secondary-text-color-hover);
        color: var(--theme-button-secondary-text-color-hover);
    }
}

.button--tertiary {
    background-color: var(--theme-button-tertiary-bg-color);
    border-color: var(--theme-button-tertiary-bg-color);
    border-radius: var(--theme-button-tertiary-border-radius);
    color: var(--theme-button-tertiary-text-color);
    padding: var(--theme-button-tertiary-padding);

    &.active,
    &:not(:focus):hover {
        background-color: var(--theme-button-tertiary-bg-color-hover);
        border-color: var(--theme-button-tertiary-text-color-hover);
        color: var(--theme-button-tertiary-text-color-hover);
    }
}

.button--quaternary {
    background-color: var(--theme-button-quaternary-bg-color);
    border-color: var(--theme-button-quaternary-bg-color);
    border-radius: var(--theme-button-quaternary-border-radius);
    color: var(--theme-button-quaternary-text-color);
    padding: var(--theme-button-quaternary-padding);

    &.active,
    &:not(:focus):hover {
        background-color: var(--theme-button-quaternary-bg-color-hover);
        border-color: var(--theme-button-quaternary-text-color-hover);
        color: var(--theme-button-quaternary-text-color-hover);
    }
}

.button--outline {
    background-color: transparent;
    border-color: var(--theme-button-bg-color);
    border-radius: var(--theme-button-border-radius);
    color: var(--theme-button-bg-color);
    padding: var(--theme-button-padding);

    &.active,
    &:not(:focus):hover {
        background-color: var(--theme-button-bg-color);
        border-color: var(--theme-button-text-color);
        color: var(--theme-button-text-color);
    }
}

.button--secondary--outline {
    background-color: transparent;
    border-color: var(--theme-button-secondary-bg-color);
    border-radius: var(--theme-button-secondary-border-radius);
    color: var(--theme-button-secondary-bg-color);
    padding: var(--theme-button-secondary-padding);

    &.active,
    &:not(:focus):hover {
        background-color: var(--theme-button-secondary-bg-color);
        border-color: var(--theme-button-secondary-text-color);
        color: var(--theme-button-secondary-text-color);
    }
}

.button--tertiary--outline {
    background-color: transparent;
    border-color: var(--theme-button-tertiary-bg-color);
    border-radius: var(--theme-button-tertiary-border-radius);
    color: var(--theme-button-tertiary-bg-color);
    padding: var(--theme-button-tertiary-padding);

    &.active,
    &:not(:focus):hover {
        background-color: var(--theme-button-tertiary-bg-color);
        border-color: var(--theme-button-tertiary-text-color);
        color: var(--theme-button-tertiary-text-color);
    }
}

.button--quaternary--outline {
    background-color: transparent;
    border-color: var(--theme-button-quaternary-bg-color);
    border-radius: var(--theme-button-quaternary-border-radius);
    color: var(--theme-button-quaternary-bg-color);
    padding: var(--theme-button-quaternary-padding);

    &.active,
    &:not(:focus):hover {
        background-color: var(--theme-button-quaternary-bg-color);
        border-color: var(--theme-button-quaternary-text-color);
        color: var(--theme-button-quaternary-text-color);
    }
}

.button--link {
    color: var(--theme-link-text-color);
    border-color: transparent;
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
    word-break: break-all;

    svg {
        display: inline;
        margin-left: 15px;
        width: 100%;
        max-width: 12px;
        vertical-align: inherit;

        .button__icon--arrow {
            max-width: 15px;
        }
    }

    &.active,
    &:not(:focus):hover {
        color: var(--theme-link-text-color-hover);
        border-color: transparent;
        background-color: transparent;
    }
}

.border-radius-none {
    border-radius: 0px !important;
}

@media screen and (max-width: 767px) {
    .m-right-60 {
        right: 60% !important;
    }

    .m-top-20 {
        top: 20% !important;
    }

    .m-top-15 {
        top: 15% !important;
    }
}
