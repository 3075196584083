.faqs--question {
    font-family: var(--theme-faqs-font-family);
}

.faq__wrapper {
    [dir="ltr"] & {
        .iconPlusMinus + .faqs--question.mr-3 {
            margin-right: 0;
        }

        .accordion-title + .iconPlusMinus.ltr\:mr-3 {
            margin-right: 0;
            margin-left: 0.75rem;
        }
    }
}
