.calendarEvent{
    .calendar__cellWrapper {
        @apply h-[265px];
    }

    .eventListView__Wrapper {
        .eventItem__Date {
            @apply w-[40px] h-[40px] leading-[2.5rem];
        }
    }

    .eventDetailView__Wrapper {
        .eventItem__Date {
            @apply w-[50px] h-[50px] leading-[3.2rem];
        }
    }

    .eventItem__title {
        &:hover span {
            @apply text-white;
        }
    }

    .eventCalendarView__Wrapper {
        .events__Wrapper {
            @apply list-inside;
        }
        .eventItem__Wrapper {
            &:hover .event-bullet:before {
                @apply text-white;
            }
        }
    }

    .eventItem__tooltipWrapper {
        max-width: 400px;
        min-width: 400px;
        &.eventItem__normalTooltip {
            top: -230%;
            left: 75%;
        }
        &.eventItem__specialTooltip {
            right: 75%;
            top: -230%;
        }
        &.eventItem__lastWeekTooltip {
            right: 75%;
            top: -420%;
        }
    }

    .line-clamp {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .line-clamp-2-line {
        -webkit-line-clamp: 2;
    }

    .line-clamp-3-line {
        -webkit-line-clamp: 3;
    }

    .line-clamp-4-line {
        -webkit-line-clamp: 4;
    }

    .event-bullet:before {
        content: "• ";
        font-size: 40px;
        position: absolute;
        left: -5px;
        top: 14px;
    }
}