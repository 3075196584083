.text-center,
.text-right {
    .rich-text {
        ul {
            li {
                list-style-position: inside;
                &::before {
                    position: relative;
                    margin-right: 20px;
                }

                li {
                    &::before {
                        margin-right: 0;
                    }
                }
            }
        }

        ol {
            list-style-position: inside;
        }
    }
}
