.globalHeader nav {
    .nav__inner,
    .nav__right {
        display: none;
    }
}

.globalHeader {
    .nav__mobile .nav__logo {
        display: none;
    }
}

.nav-stuck {
    .globalHeader__main {
        display: none;
    }

    .globalHeader__logo {
        .nav__logoWrap--resize {
            transform: translateX(-10px);
        }
    }

    .globalHeader__utility {
        flex-direction: row-reverse;

        input {
            margin-top: 0;
        }

        svg {
            margin-top: 4px;
        }
    }

    .nav__mobile .nav__logo {
        display: block;
    }
}
