.border-transparent {
    border-color: transparent;
}

.hover\:border-primary:hover,
.border-primary {
    border-color: var(--theme-border-primary-color);
}
.hover\:border-invert:hover,
.border-invert {
    border-color: var(--theme-color-primary2);
    color: var(--theme-text-primary-invert-color);
    fill: var(--theme-text-primary-invert-color);
}
