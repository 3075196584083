.card {
    border-radius: var(--theme-cards-border-radius);
}

.card-tag {
    border-radius: var(--theme-category-tags-border-radius);
    border-color: var(--theme-category-tags-bg-color);
    background-color: var(--theme-category-tags-bg-color);
    color: var(--theme-category-tags-text-color);
}

.card__fixedRatio .uiImage {
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;
}
