.sharePage {
    .sharePage__label {
        line-height: 40px;
    }

    .sharePage__svg {
        fill: var(--theme-share-page-fill);
        color: var(--theme-share-page-fill);
    }

    .sharePage__social:not(:first-child) {
        margin-left: 10px;
    }

    .sharePage__button {
        cursor: pointer;
        border-radius: var(--theme-share-page-border-radius);
    }
}
