.rich-text {
    * {
        letter-spacing: 0px;
        word-break: break-word;
    }

    a[href*="#"] {
        display: inline-block;
        letter-spacing: 1px;
        margin-left: 5px;

        //anchor tags in richtexts on footer
        main ~ .contentGridItem & {
            margin-left: 0;
        }
    }

    ul,
    ol {
        list-style: inherit;
        list-style-position: outside;
        margin-left: 10px;
        padding-left: 25px;

        li {
            &::marker {
                color: var(--theme-bullet-color, inherit);
            }

            margin-bottom: 1.5rem;

            li {
                margin-top: 0.75rem;

                li {
                    margin-top: 0.5rem;
                }
            }

            a[href*="#"] {
                display: inline;
                margin-left: 0;
            }
        }

        [dir="rtl"] > & {
            text-align: right;
            padding-left: 0;
            margin-left: 0;
            padding-right: 25px;
            margin-right: 10px;
        }
    }

    ul {
        position: relative;

        > li {
            @include listStyleType($type: disc);

            ul {
                > li {
                    @include listStyleType($type: circle);
                    ul {
                        > li {
                            @include listStyleType($type: square);
                        }
                    }
                }
            }
        }
    }

    ol {
        counter-reset: counter;

        li {
            counter-increment: counter;
            margin-bottom: 1.5rem;
            padding-left: 5px;
            position: relative;
            list-style: decimal;

            a[href*="#"] {
                margin-left: 0;
            }

            [dir="rtl"] > & {
                padding-left: 0;    
                padding-right: 5px;
            }
        }
    }

    a {
        color: inherit;
        cursor: pointer;
        text-decoration: inherit;

        sup,
        sub {
            display: inline-block;
            font-size: 60%;
            letter-spacing: 1px;
            margin-left: 1px;
        }
    }

    sup,
    sub {
        display: inline-block;
        font-size: 60%;
        letter-spacing: 1px;
        margin-left: 1px;
    }

    p {
        &:only-child {
            margin-bottom: 0 !important;
        }

        overflow-wrap: break-word;
        word-break: break-word;
        -webkit-font-smoothing: antialiased;

        strong {
            font-weight: bold;

            a {
                font-weight: bold;
            }
        }

        em {
            font-style: italic;

            a {
                font-style: italic;
            }
        }

        u {
            text-decoration: underline;

            a {
                text-decoration: underline;
            }
        }
    }

    img,
    svg {
        display: inline;
    }

    &.simple-callout-text {
        @media (min-width: 1280px) {
            p {
                max-width: 90%;
            }
        }
    }
}

.rich-text * {
    line-height: var(--theme-rich-text-line-height);
}

.rich-text p {
    margin-bottom: var(--theme-rich-text-p-vertical-spacing);
}

.rich-text h1,
.rich-text h2,
.rich-text h3,
.rich-text h4,
.rich-text h5,
.rich-text h6 {
    font-family: var(--theme-rich-text-heading-font-family);
    color: var(--theme-rich-text-heading-font-color);
    font-weight: var(--theme-rich-text-heading-weight);
    font-style: normal;
    letter-spacing: 0;
    line-height: 100%;
    margin-bottom: 1.5rem;
}

.rich-text h1 {
    font-size: var(--title-1-font-size);
}
.rich-text h2 {
    font-size: var(--title-2-font-size);
}
.rich-text h3 {
    font-size: var(--title-3-font-size);
}
.rich-text h4 {
    font-size: var(--title-4-font-size);
}
.rich-text h5 {
    font-size: var(--title-5-font-size);
}
.rich-text h6 {
    font-size: var(--title-6-font-size);
}
