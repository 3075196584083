.video-wrapper {
    padding-top: 58.125%;


    .facade {
        &:after{
            content: "";
            background: rgb(0,0,0);
            background: -moz-linear-gradient(185deg, rgba(0,0,0,0.3618040966386554) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(185deg, rgba(0,0,0,0.3618040966386554) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(185deg, rgba(0,0,0,0.3618040966386554) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }

        &__title {
            text-shadow: rgb(0 0 0 / 50%) 0 0 2px;         
        }

        &__button {
        
            &--yt {
                &:after {
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    margin-left: -10px;
                    margin-top: -10px;
                }

                span{
                    width: 70px;
                    height: 50px;
                    border-radius: 15px 15px 15px 15px / 45px 45px 45px 45px;
                    background-color: #f00;
                }
            }

            &--bc {

                &:after{
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;  
                    margin-left: -5px;
                    margin-top: -15px;  
                }

                span{
                    width: 98px;
                    height: 98px;
                    border-radius: 999px;
                    background-color: rgba(0, 0, 0, 0.45);
                }

                &:hover {
                    span {
                        background-color: #d52484;
                    }
                }
            }

            &--icon {
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    border-left: 20px solid #fff;
                }
            }
        }

    }

    .vimeo-iframe{
        display: flex;

        iframe{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.video-player {
    transform: translate(-50%, -50%);
}

.podcast-player {
    background: transparent;
    .vjs-poster,
    .bg-default {
        background: transparent;
        background-image: none !important;
        background-color: transparent;
    }

    .vjs-dock-shelf .vjs-button.vjs-share-control {
        display: none;
    }

    video {
        display: none;
    }

    .vjs-dock-text,
    .vjs-dock-shlef {
        display: none;
    }
}

.card__video-container,
.dam-video-player-container {
    &:hover {
        .play-button {
            background: var(--theme-bg-default-color, var(--theme-button-bg-color)); /** button color as fallback **/
        }
        .play-button-pause {
            visibility: visible;
        }

        @media (max-width: $width-tablet-max) {
            .play-button-pause {
                opacity: 0;
                transition: opacity 1s ease-in-out;
            }

            .play-button {
                visibility: hidden;
            }
        }
    }
    .play-button-pause {
        visibility: hidden;
    }
}

.play-button {
    transform: translate(-50%, -50%);

    &:before {
        content: "";
        outline: none;
        height: 25px;
        width: 0;
        position: absolute;
        top: 51.5%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 25ms all ease;
        margin: 0 auto;
        border-radius: 1px;
        border-style: solid;
        border-width: 12.5px 0 12.5px 23px;
        border-color: transparent transparent transparent rgba(255, 255, 255, 1);
    }

    &.play-button-pause {
        &:before {
            border-style: double;
            border-width: 0px 0 0px 22px;
        }
    }
}

.transcript-active {
    box-shadow: 0 2px 1px -1px rgba($color-black, 0.1);

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
        width: 11px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.ytp-button {
    &:focus {
        outline: solid !important;
    }
}

.videoHero {
    height: 100%;
    max-height: 100%;
    min-height: 250px;

    @media (min-width: 768px) {
        max-height: 550px;
        min-height: 465px;
    }

    @media (min-width: 1024px) {
        max-height: 725px;
        min-height: 565px;
    }
}

.video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 100vw;
    min-width: 1440px;
    min-height: 100%;
    background-color: white;
    transition: filter 1000ms;

    > .vjs-big-play-button,
    .vjs-control-bar {
        display: none;
    }
}

.vjs-playlist-player-container {
    .vjs-playlist-sidebar-vertical {
        z-index: 20;
    }
}