/* Transition effects */

.contentGridItem--wipeFromLeft {
    align-items: stretch;
    overflow: hidden;
    width: 0vw;

    .band__wrapper {
        width: 100vw;
    }
}

.contentGridItem--blurIn {
    .contentGridItem__container {
        filter: blur(5px);
        background-color: #000;
    }
}

.contentGridItem--videoBg {
    .animateImage {
        overflow: hidden;
    }
}

.contentGridItem--wipeOut {
    overflow: hidden;
    width: 50vw;
    margin: 0 auto;
}

.contentGridItem--fixedBackground {
    .animateImage {
        background-attachment: fixed;
    }
}

.contentGridItem--fixedBackgroundwtitle {
    .animateImage {
        background-attachment: fixed;
    }
}
